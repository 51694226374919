app.service('surveyManagePayerService',
  ['$timeout', 'portalHelper', 'apiHelper', '$ngConfirm', 'CONSTANTS', '$filter', '$location', '$rootScope',
    function ($timeout, portalHelper, apiHelper, $ngConfirm, CONSTANTS, $filter, $location, $rootScope) {
      var service = this;

      function generateSelectAllCheckbox(data) {
        return '<label><input type=\'checkbox\' id=\'chk-select-all\' /></label>';
      }

      function areNoCheckboxesSelected(tableId) {
        return $('#' + tableId + ' input[type=\'checkbox\'][name=\'selectedPayers\']:checked').length === 0;
      }

      function areAllCheckboxesOnPageSelected(tableId) {
        return $('#' + tableId + ' input[type=\'checkbox\'][name=\'selectedPayers\']:unchecked').length === 0;
      }

      service.adjustSelectAllCheckbox = function (tableId) {
        $timeout(function () {
          if (areNoCheckboxesSelected(tableId)) {
            $('#' + tableId + ' #chk-select-all').prop('indeterminate', false);
            $('#' + tableId + ' #chk-select-all').prop('checked', false);

            $('#' + tableId + ' #header-message-row').hide();
          } else if (areAllCheckboxesOnPageSelected(tableId)) {
            $('#' + tableId + ' #chk-select-all').prop('indeterminate', false);
            $('#' + tableId + ' #chk-select-all').prop('checked', true);
          } else {
            $('#' + tableId + ' #chk-select-all').prop('indeterminate', true);
            $('#' + tableId + ' #header-message-row').hide();
          }
        }, 0);
      };

      service.getActionMenu = function (row) {
        var menu = [
          '<div class=\'mat ui btn-group\'>',
          '<button type=\'button\' class=\'btn mat ui small primary button dropdown-toggle\' data-bs-toggle=\'dropdown\' aria-haspopup=\'true\' aria-expanded=\'false\' data-ng-disabled=\'ul_has_li_items("burger-menu-' + row.id + '") == false\')\'>',
          '<i class=\'fa fa-bars fa-fw\'></i>',
          '</button>',
          '<ul id="burger-menu-' + row.id + '" class="mat ui dropdown-menu dropdown-menu-right">'
        ];

        if (row.assignments_are_sendable === true) {
          if (row.is_sendable && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
            if (!row.is_sent) {
              menu.push('<li><a data-ng-click=\'sendAssignmentsToPayers(' + row.id + ')\'>\
                      <i class=\'fa fa-user-plus fa-fw me-2\'></i>Send</a></li>');
            } else {
              menu.push('<li><a data-ng-click=\'sendAssignmentsToPayers(' + row.id + ')\'>\
                        <i class=\'fa fa-calendar-check-o fa-fw me-2\'></i>Resend</a></li>');
            }
          }

          if (row.is_recallable) {
            if (row.accepted && !row.submitted_datetime && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
              menu.push('<li><a data-ng-click=\'confirmBeforeRecall(' + row.assignment_id + ')\'>\
                        <i class=\'fa fa-user-times fa-fw me-2\'></i>Recall</a></li>');
            }

            if (row.accepted == null && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
              menu.push('<li><a data-ng-click=\'recallAssignment(' + row.assignment_id + ')\'>\
                        <i class=\'fa fa-user-times fa-fw me-2\'></i>Recall</a></li>');
            }
          }

          if (row.is_remindable && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
            menu.push('<li><a data-ng-click=\'remindPayer(' + row.id + ')\'>\
                        <i class=\'fa fa-clock-o fa-fw me-2\'></i>Remind</a></li>');
          }

          if (row.is_end_time_settable && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
            menu.push('<li><hr/></li>');
            menu.push('<li><a data-ng-click=\'extendCustomExpirationForPayer(' + row.assignment_id + ', 24)\'>\
                    <i class=\'fa fa-clock-o fa-fw me-2\'></i>Extend +24 hours</a></li>');
            menu.push('<li><a data-ng-click=\'extendCustomExpirationForPayer(' + row.assignment_id + ', 48)\'>\
                    <i class=\'fa fa-clock-o fa-fw me-2\'></i>Extend +48 hours</a></li>');
            menu.push('<li><a data-ng-click=\'extendCustomExpirationForPayer(' + row.assignment_id + ')\'>\
                    <i class=\'fa fa-clock-o fa-fw me-2\'></i>Extend by Custom Time</a></li>');
            menu.push('<li><a data-ng-click=\'setEndTimeForSelectedPayers(' + row.assignment_id + ')\'>\
                    <i class=\'fa fa-clock-o fa-fw me-2\'></i>Set End Time</a></li>');
          }
        }

        if (!row.is_survey_cancelled && row.is_cancellable && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)) {
          menu.push(
            '<li><a data-ng-click=\'removePayer([' + row.assignment_id + '])\'><i class=\'fa fa-remove fa-fw\'></i>Remove</a></li>'
          );
        }
        if ($rootScope.has_any_of_permissions([$rootScope.PERMISSIONS.CAN_UPDATE_PAYER, $rootScope.PERMISSIONS.CAN_VIEW_PAYER]) ||
          (!row.locked && row.active && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_ACCESS_ON_BEHALF_OF_PAYER))) {
          menu.push('<li><hr/></li>');
        }
        if ($rootScope.has_permission($rootScope.PERMISSIONS.CAN_UPDATE_PAYER)) {
          menu.push(
            '<li><a href=\'#/Admin/Payer/Edit/' + row.id + '\'>',
            '<i class=\'fa fa-pencil me-2\'></i> Edit Payer</a></li>'
          );
        }
        if ($rootScope.has_permission($rootScope.PERMISSIONS.CAN_VIEW_PAYER)) {
          menu.push(
            '<li><a href=\'#/Admin/Payer/View/' + row.id + '\'>',
            '<i class=\'fa fa-user me-2\'></i> View Payer</a></li>'
          );
        }
        if (!row.locked && row.active && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_ACCESS_ON_BEHALF_OF_PAYER)) {
          menu.push(
            '<li><a href="javascript:" class=\'payer-impersonate-link\' data-ng-click=\'impersonate_by_admin(' + row.id + ')\' >',
            '<i class=\'fa fa-user-secret fa-fw me-2\'></i> Access on Behalf of</a></li>'
          );
        }
        menu.push('</ul></div>');

        return menu.join('\n');
      };

      function generateNameCol(row) {
        var locked = '';
        if (row.locked) {
          locked = '<i class="red fa fa-lock fa-fw" title="This Payer is locked"></i> ';
        }

        var deactivated = '';
        if (!row.active) {
          deactivated = '<i class=\'red fa fa-fw fa-ban\' title=\'This client user is deactivated\'></i> ';
        }

        var newPayer = '';
        if (row.new_payer) {
          newPayer = '<span class=\'new-payer-badge\' title=\'New Payer\'>N</span>';
        }

        return newPayer + locked + deactivated + row.full_name;
      }

      service.initTableMessageHeader = function (tableId, selectAllMethodName, deselectAllMethodName,
        checkedPayersCountMethodName, $scope, $compile) {
        var dt = $('#' + tableId).DataTable();
        var colLength = $(dt.table().header()).find('tr th').length;
        var headerRowId = '#' + tableId + ' #header-message-row';

        if ($(headerRowId).length == 0) {
          var numOfPayers = $scope.checkedPayersCountOnPage();
          var payerText = numOfPayers == 1 ? ' payer has ' : ' payers have ';

          var htmlMessage = ['<tr style=\'display: none;\' id=\'header-message-row\'><td class=\'center aligned\' colspan=\'' + colLength + '\'>',
            '<div style=\'display: none;\' class=\'all-pages-checked\'>',
            'All payers have been selected. <a href=\'javascript:\' data-ng-click=\'' + deselectAllMethodName + '()\'>Clear Selection</a>',
            '</div>',
            '<div style=\'display: none;\' class=\'current-page-checked\'>',
            '<span data-ng-bind=\'' + checkedPayersCountMethodName + '()\'></span>' + payerText + 'been selected on this page.',
            '<a data-ng-click=\'' + selectAllMethodName + '()\' href=\'javascript:\'>Click here</a> to select all payers on all pages.',
            '</div>',
            '</td></tr>'].join('\n');

          $(dt.table().header()).append(htmlMessage);

          $compile(angular.element(headerRowId).contents())($scope);
        }
      };

      service.toggleCheckboxesToSelectMessage = function (tableId, allCheckboxesSelected) {
        var headerId = '#' + tableId + ' #header-message-row';
        $(headerId).show();

        if (allCheckboxesSelected) {
          $(headerId + ' .all-pages-checked').show();
          $(headerId + ' .current-page-checked').hide();
        } else {
          $(headerId + ' .all-pages-checked').hide();
          $(headerId + ' .current-page-checked').show();
        }
      };

      function generateCheckboxCellForPayer(row) {
        return '<label><input type=\'checkbox\' data-ng-click=\'adjustSelectAllCheckbox()\' ' +
          'checklist-model=\'selectedPayers\' name=\'selectedPayers\' checklist-value=\''
          + row.id + '\' data-ng-disabled=\'activeAndUnlockedPayer ? ' + (!row.active) + ' : false\' /></label>';
      }

      function generateNameCellForPayer(row) {
        return '<a data-ng-if="has_permission(PERMISSIONS.CAN_VIEW_PAYER)" href=\'#/Admin/Payer/View/' + row.id + '\' data-ng-click=\'closeAssignPayerPopup()\'>' + row.full_name + '</a>' +
        '<span data-ng-if="!has_permission(PERMISSIONS.CAN_VIEW_PAYER)">' + row.full_name + '</span>';
      }

      function generateNameForRespondentType(row) {
        return _.pluck(row.respondent_types, 'name').join(', ');
      }

      function generateIconsCellForPayer(row) {
        var logged_in_icon = '<i class="green fa fa-thumbs-up fa-fw" title="This Payer has logged in"></i> ';
        var not_logged_in_icon = '<i class="red fa fa-thumbs-down fa-fw" title="This Payer has never logged in"></i> ';
        var welcome_email_sent_icon = '<i class="green fa fa-envelope-open fa-fw" title="Welcome invitation has been sent"></i> ';
        var welcome_email_not_sent_icon = '<i class="red fa fa-envelope fa-fw" title="Welcome invitation not sent"></i> ';

        var logged_in_status = row.last_login
          ? logged_in_icon : not_logged_in_icon;

        var welcome_email_status = row.welcome_email_sent
          ? welcome_email_sent_icon : welcome_email_not_sent_icon;

        var locked = '';
        if (row.locked === true) {
          locked = '<i class="red fa fa-lock fa-fw" title="This Payer is locked"></i> ';
        }

        var deactivated = '';
        if (row.active === false) {
          deactivated = '<i class=\'red fa fa-fw fa-ban\' title=\'This Payer is deactivated\'></i> ';
        }

        var newPayer = '';
        if (row.new_payer === true) {
          newPayer = '<span class=\'new-payer-badge\' title=\'New Payer\'>N</span>';
        }

        return '<span class="nowrap">' +
          logged_in_status +
          welcome_email_status +
          newPayer +
          locked +
          deactivated +
          '</span>';
      }

      function generateCountryCellForPayer(country_name, country_flag) {
        var flagImage = '';
        if (country_flag) {
          var flagUrl = portalHelper.getFlagUrl(country_flag);
          flagImage = '<img style=\'margin-right: 4px\' src=\'' + flagUrl + '\' class=\'tab-flag\' title=\'' + country_name + '\' />';
        }

        return flagImage + '<span>' + country_name + '</span>';
      }

      function fetchTableButtonsDropdown(row) {
        var menu = [
          '<div class=\'mat ui btn-group\'>',
          '<button type=\'button\' class=\'btn mat ui small primary button dropdown-toggle\' data-bs-toggle=\'dropdown\' aria-haspopup=\'true\' aria-expanded=\'false\'>',
          '<i class=\'fa fa-bars fa-fw\'></i>',
          '</button>',
          '<ul class="mat ui dropdown-menu dropdown-menu-right">',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a href=\'#/Admin/Payer/Edit/' + row.id + '\'>',
          '<i class=\'fa fa-pencil me-2\'></i> Edit Payer</a></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_VIEW_PAYER)"><a href=\'#/Admin/Payer/View/' + row.id + '\'>',
          '<i class=\'fa fa-user me-2\'></i> View Payer</a></li>',
          '<li data-ng-if=\'' + (!row.locked && row.active) + ' && (has_permission(PERMISSIONS.CAN_ACCESS_ON_BEHALF_OF_PAYER)) \'><a href="javascript:" class=\'payer-impersonate-link\' \
                        data-ng-click=\'impersonate_by_admin(' + row.id + ')\' >',
          '<i class=\'fa fa-user-secret fa-fw me-2\'></i> Access on Behalf of</a></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_EXPORT_PAYER_REPORT)"><a href=\'javascript:\' data-ng-click=\'payer_activity_report(' + row.id + ');\'>',
          '<i class=\'fa fa-table fa-fw me-2\'></i> Activity Report</a></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_VIEW_PAYER)"><a href=\'#/Admin/PayerStatistics/' + row.id + '\'>',
          '<i class=\'fa fa-pie-chart fa-fw me-2\'></i> Statistics</a></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)" class=\'divider\'></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a href=\'#/Admin/Payer/AdditionalFees/' + row.id + '\'>',
          '<i class=\'fa fa-usd fa-fw me-2\'></i> Additional Fees</a></li>',
          '<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a data-ng-click=\'show_popup_to_generate_payer_expertise_form_link(' + row.id + ', "' + row.country.country_code + '",' + JSON.stringify(_.pluck(row.respondent_types, 'name')) + ')' + '\'>',
          '<i class=\'fa fa-file-text fa-fw me-2\'></i> New Expertise Form</a></li>'
        ];

        if (row.locked) {
          menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a data-ng-click=\'update_lock_status_for_userid(' + row.id + ', false);\'><i class=\'fa fa-unlock fa-fw me-2\'></i> Unlock Payer</a></li>');
        } else {
          menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a data-ng-click=\'update_lock_status_for_userid(' + row.id + ', true);\'><i class=\'fa fa-lock fa-fw me-2\'></i> Lock Payer</a></li>');
        }

        if (row.active) {
          menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a data-ng-click=\'update_active_status_for_userid(' + row.id + ', false)\'>' +
            '<i class=\'fa fa-ban fa-fw me-2\'></i> Deactivate Payer</a></li>');
        } else {
          menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a data-ng-click=\'update_active_status_for_userid(' + row.id + ', true)\'>' +
            '<i class=\'fa fa-check fa-fw\'></i> Activate Payer</a></li>');
        }

        if (row.active && !row.locked) {
          if (row.last_login) {
            menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a ng-click="passwordResetAsAdmin(' + row.id + ', \'payer\')" href="javascript:">' +
              '<i class="fa fa-fw fa-key me-2"></i> Email Password Reset</a></li>');
          } else {
            menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a ng-click="sendWelcomeEmail(' + row.id + ', \'payer\')" href="javascript:">' +
              '<i class="fa fa-fw fa-paper-plane-o me-2"></i> Send Welcome Email</a></li>');
          }

          menu.push('<li data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_PAYER)"><a ng-click="passwordResetLinkAsAdmin(' + row.id + ', \'payer\')" href="javascript:">' +
          '<i class="fa fa-fw fa-link me-2"></i> Password Set/Reset Link</a></li>');
        }

        menu.push('</ul></div>');
        return menu.join('\n');
      }

      var constructTimeCell = function (dateVal, timezone) {
        var displayTime = portalHelper.convert_to_local_time(dateVal,
          CONSTANTS.dateTimeFormatMoment.MEDIUM_24H_TIME_NO_SEC);

        var payerTime = moment.tz(dateVal, timezone)
          .format(CONSTANTS.dateTimeFormatMoment.MEDIUM_12H_TIME_NO_SEC_TIMEZONE);

        return '<span class="tooltipster table-time-cell" title="Payer\'s local time: ' + payerTime + '">' + displayTime + '</span>';
      };

      var getLatestNote = function (notes) {
        return notes.reduce(function (a, b) {
          return a.created_datetime > b.created_datetime ? a : b;
        });
      };

      const getPinnedNotes = function (notes) {
        const pinnedNotes =
        notes
          .filter(note => note.is_pinned)
          .sort((noteA, noteB) => {
            if (noteA.created_datetime && noteB.created_datetime) {
              return new Date(noteB.created_datetime) - new Date(noteA.created_datetime);
            }
          })
          .map(pinnedNote => {
            if (pinnedNote.pinned_until_datetime) {
              const now = new Date();
              const pinnedUntilDatetime = new Date(pinnedNote.pinned_until_datetime);
              if (pinnedUntilDatetime < now) {
                return null;
              }
            }
            const mesage = pinnedNote.message.length > 200 ? pinnedNote.message.substring(0, 200) + '...' : pinnedNote.message;
            const createdDate = moment(pinnedNote.created_datetime).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
            return `${pinnedNote.created_by.first_name} (${createdDate}): ${mesage}`;
          });

        const filteredPinnedNotes = pinnedNotes.filter(note => note !== null);
        return filteredPinnedNotes.length > 0 ? filteredPinnedNotes : [];
      };

      var renderPinnedNotes = function (data, type, row) {
        if (Array.isArray(row.notes) && row.notes.length >= 1) {
          var pinnedNotes = getPinnedNotes(row.notes);
          if (pinnedNotes.length > 0) {
            if (type === 'display') {
              var pinnedNotesHtml = pinnedNotes.map(note => `<div class="py-2 note-separator">${note}</div>`).join(' ');
              return `<div>${pinnedNotesHtml}</div>`;
            }
            if (type === 'export') {
              return pinnedNotes.map(note => `${note}\r\n\r\n`).join('');
            }
          } else {
            const showPinnedNotesLink = $location.path().indexOf('/Respondents') === -1;
            return `<div>${row.notes.length} notes added, 0 pinned. <a data-ng-if="${showPinnedNotesLink}" href="/admin/#/Admin/Payer/View/${row.id}?active_tab=${CONSTANTS.adminPayerViewTabs.payer_notes}">Pin notes</a></div>`;
          }
        }
        return '-';
      };

      service.views = [
        {
          name: 'Payer Tracking View',
          columns: [
            {
              title: '',
              data: 'id',
              visible: false,
              className: 'skipCol'
            },
            {
              title: generateSelectAllCheckbox(),
              defaultContent: '',
              responsivePriority: 0,
              className: 'payer-checkbox-cell center aligned skipCol',
              orderable: false,
              searchable: false,
              render: function (data, type, row) {
                return '<label><input id=\'{{row.id}}\' type=\'checkbox\' class=\'align-middle\'checklist-model=\'selectedPayers\''
                  + ' name=\'selectedPayers\' data-ng-click=\'adjustSelectAllCheckboxForAssignedPayers()\' value=\''
                  + row.id + '\'/></label>';
              }
            },
            {
              data: 'time_spent_last_updated_datetime',
              render: function (data, type, row) {

                let onlineStatus = '<i class="text-muted fa fa-circle fa-xs" style="font-size: 0.8rem" uib-tooltip="Offline"></i> ';
                if (!data) {
                  return onlineStatus;
                }

                let lastSeen = (new Date() - new Date(data)) / 1000;
                if (lastSeen < 120) { 
                  onlineStatus = '<i class="green fa fa-circle fa-xs" style="font-size: 0.8rem" uib-tooltip="Online"></i> ';
                } else if (lastSeen < 300) { 
                  onlineStatus = '<i class="orange fa fa-circle fa-xs" style="font-size: 0.8rem" uib-tooltip="Idle"></i> ';
                }

                return onlineStatus;
              }
            },
            {
              title: 'State',
              data: 'payer_state_code',
              defaultContent: ''
            },
            {
              title: 'Payer Name',
              data: 'full_name',
              responsivePriority: 0,
              defaultContent: '',
              render: function (data, type, row) {
                if (type === 'sort' || type === 'export') {
                  return row.full_name;
                }
                return generateNameCol(row);
              }
            },
            {
              title: 'Archetype',
              data: 'archetype',
              responsivePriority: 0,
              render: function (data, type, row) {
                var result = '<a data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)" class="ms-2 tooltipster" href="javascript:" ' +
                  'data-ng-click="edit_archetype(' + row.assignment_id + ', \'assign\');" title="Edit Archetype"><i class="fa fa-pencil-square fa-fw"></i></a>';
                if (data) {
                  result += '<span>' + data + '</span>';
                } else {
                  result += '<span>N/A</span>';
                }
                return result;
              }
            },
            {
              title: 'Email ID',
              data: 'email',
              render: function (data, type, row) {
                return ('<a href="mailto:'+ data +'" target="_blank">'+ data +'</a>');
              }
            },
            {
              title: 'Respondent Type',
              data: 'respondent_type',
              defaultContent: '',
              render: function (data, type, row) {
                return data.name;
              }
            },
            {
              title: 'Country Of Expertise',
              defaultContent: '',
              render: function (data, type, row) {
                return generateCountryCellForPayer(row.country.name, row.country.flag);
              }
            },
            {
              title: 'Payer Recruited By',
              defaultContent: '',
              data: 'recruited_by',
              render: function (data, type, row) {
                if (data) {
                  return '<a data-ng-if="has_permission(PERMISSIONS.CAN_UPDATE_ASSIGNED_PAYERS)" data-ng-click=\'changeRecruitedBy(' + JSON.stringify(row) + ')\' class=\'clickable\'><i class=\'fa fa-pencil\'></i></a> ' +
                    data.first_name + ' ' + data.last_name;
                } else {
                  return '';
                }
              }
            },
            {
              title: 'Number of Questions',
              data: 'question_count',
              defaultContent: ''
            },
            {
              title: 'Question Equivalency',
              data: 'question_to_pay_count',
              defaultContent: ''
            },
            {
              title: 'Sent at',
              data: 'last_sent_datetime',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                if (type==='sort') {
                  return row.last_sent_datetime;
                }
                if (row.last_sent_datetime) {
                  return constructTimeCell(new Date(row.last_sent_datetime), row.time_zone_code);
                }
              }
            },
            {
              title: 'Respond by',
              data: 'respond_by_datetime',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                if (type==='sort') {
                  return row.respond_by_datetime;
                }
                if (row.respond_by_datetime) {
                  return constructTimeCell(new Date(row.respond_by_datetime), row.time_zone_code);
                }
              }
            },
            {
              title: 'Last Reminder',
              data: '',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                if (row.is_remindable && row.sent_reminders.length > 0) {
                  var mostRecentDate = new Date(Math.max.apply(null, row.sent_reminders.map(function (e) {
                    return new Date(e.sent_datetime);
                  })));
                  if (type==='sort') {
                    return mostRecentDate;
                  }
                  return constructTimeCell(new Date(mostRecentDate), row.time_zone_code);
                }
              }
            },
            {
              title: 'Response Received',
              data: 'submitted_datetime',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                if (type==='sort') {
                  return row.submitted_datetime;
                }
                if (row.submitted_datetime) {
                  return constructTimeCell(new Date(row.submitted_datetime), row.time_zone_code);
                }
              }
            },
            {
              title: 'Questions Responded To',
              data: 'questions_responded_count',
              defaultContent: '',
              render: function (data, type, row) {
                return row.questions_responded_count;
              }
            },
            {
              title: 'Time Spent on Survey',
              data: 'time_spent',
              defaultContent: '-',
              render: function (data, type, row) {
                if (type === 'sort' && row.time_spent_duration) {
                  return ('' + parseInt(row.time_spent_duration)).padStart(20, '0');
                }
                else {
                  if (row.time_spent) {
                    return row.time_spent;
                  } else {
                    return '0 minutes';
                  }
                }
              }
            },
            {
              title: 'Waiting for Clarifications',
              data: 'has_pending_clarification',
              defaultContent: '',
              render: function (data, type, row, meta) {
                if (row.has_pending_clarification === true) {
                  return 'Yes';
                } else if (row.has_pending_clarification === false) {
                  return 'No';
                } else {
                  return '';
                }
              }
            },
            {
              title: 'Response Status',
              data: 'badge',
              defaultContent: ''
            },
            {
              title: 'Relevance Rating',
              data: 'relevance_rating',
              defaultContent: '-',
              render: function(data, type, row) {

                if (type == 'sort') {
                  return data ? data.rating : 0;
                }

                if (data == null) {
                  return '-';
                }
                let stars = '';
                for (let i = 0; i < 3; i++) {
                  if (i < data.rating) {
                    stars += '<i class="fa fa-star blue"></i>';
                  }
                  else {
                    stars += '<i class="fa fa-star-o blue"></i>';
                  }
                }
                return stars;
              }
            },
            {
              title: 'Action Required',
              data: 'id',
              render: function (data, type, row) {
                if (row.submitted_datetime && $rootScope.has_permission($rootScope.PERMISSIONS.CAN_VIEW_SURVEY_RESPONSE)) {
                  return '<a href="#/Admin/PayerApproval/' + row.response_id + '">Review Response</a>';
                }
                return 'None';
              }
            },
            {
              data: 'id',
              className: 'skipCol',
              orderable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return service.getActionMenu(row);
              }
            }

          ]
        }
      ];

      function generateProjectCodesWithToolTips(data, type, row) {
        return _.map(data, function (assignment) {
          return '<span\
                    class="recruited-by tooltipster"\
                    title="Recruited by: ' + (assignment.recruited_by_full_name || 'Unavailable') + '">'
            + assignment.project_code +
            '</span>';
        }).join(', ')
          || '-';
      }

      service.payerTableViews = [
        {
          name: 'General View',
          value: CONSTANTS.payersTableViews.GENERAL,
          order: [[4, 'asc']],
          columns: [
            {
              title: '',
              data: 'id',
              visible: false,
              className: 'skipCol'
            },
            portalHelper.columnUserListTfaHidden,
            {
              title: generateSelectAllCheckbox(),
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              searchable: false,
              data: null,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(data);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              responsivePriority: 0,
              width: 50,
              className: 'skipCol',
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              responsivePriority: 0,
              className: 'payer-name',
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country Of Expertise',
              data: null,
              render: function (data, type, row) {
                return generateCountryCellForPayer(row.country.name, row.country.flag);
              }
            },
            {
              title: 'Email',
              data: 'email'
            },
            {
              title: 'Job Title',
              data: 'job_title'
            },
            {
              title: 'Payer Type',
              data: null,
              render: function (data, type, row) {
                if (row.payer_type_display_name) {
                  return row.payer_type_display_name;
                }
                return 'N/A';
              }
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              className: 'center aligned skipCol',
              data: null,
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            },
            {
              title: '',
              data: 'description',
              className: 'skipCol',
              visible: false
            },
            {
              title: '',
              data: 'anonymized_profile',
              className: 'skipCol',
              defaultContent: '-',
              visible: false
            },
            {
              title: '',
              data: 'additional_information',
              className: 'skipCol',
              visible: false
            }
          ]
        },
        {
          name: 'Qualifications View',
          value: CONSTANTS.payersTableViews.QUALIFICATIONS,
          order: [[3, 'asc']],
          columns: [
            {
              title: '',
              data: 'id',
              className: 'skipCol',
              visible: false
            },
            {
              title: generateSelectAllCheckbox(),
              data: null,
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              searchable: false,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(full);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              responsivePriority: 0,
              className: 'skipCol',
              width: 50,
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country of Expertise',
              data: null,
              render: function (data, type, row) {
                return generateCountryCellForPayer(row.country.name, row.country.flag);
              }
            },
            {
              title: 'HCP',
              data: 'is_healthcare_provider',
              className: 'center aligned',
              render: function (data, type, row) {
                if (data == true) {
                  return '<i class=\'fa fa-fw fa-check\'></i>';
                } else {
                  return '<i class=\'fa fa-fw fa-times\'></i>';
                }
              }
            },
            {
              title: 'Locality',
              data: 'localities'
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              data: null,
              className: 'center aligned skipCol',
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            },
            {
              title: '',
              data: 'description',
              className: 'skipCol',
              visible: false
            },
            {
              title: '',
              data: 'anonymized_profile',
              className: 'skipCol',
              defaultContent: '-',
              visible: false
            },
            {
              title: '',
              data: 'additional_information',
              className: 'skipCol',
              visible: false
            }
          ]
        },
        {
          name: 'Contact Details View',
          value: CONSTANTS.payersTableViews.CONTACT_DETAILS,
          order: [[3, 'asc']],
          columns: [
            {
              title: '',
              data: 'id',
              className: 'skipCol',
              visible: false
            },
            {
              title: generateSelectAllCheckbox(),
              data: null,
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              searchable: false,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(full);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              responsivePriority: 0,
              className: 'skipCol',
              width: 50,
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country Of Expertise',
              data: null,
              render: function (data, type, row) {
                return generateCountryCellForPayer(row.country.name, row.country.flag);
              }
            },
            {
              title: 'Email',
              data: 'email'
            },
            {
              title: 'Alternate Email',
              data: 'alternate_email'
            },
            {
              title: 'Telephone Number',
              data: 'telephone'
            },
            {
              title: 'Mobile Number',
              data: 'mobile'
            },
            {
              title: 'Postal Address',
              data: 'postal_address'
            },
            {
              title: 'Timezone',
              data: 'time_zone_display'
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              data: null,
              className: 'center aligned skipCol',
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            },
            {
              title: '',
              data: 'description',
              className: 'skipCol',
              visible: false
            },
            {
              title: '',
              data: 'anonymized_profile',
              className: 'skipCol',
              defaultContent: '-',
              visible: false
            },
            {
              title: '',
              data: 'additional_information',
              className: 'skipCol',
              visible: false
            }
          ]
        },
        {
          name: 'Statistics View',
          value: CONSTANTS.payersTableViews.STATISTICS,
          order: [[3, 'asc']],
          columns: [
            {
              title: '',
              data: 'id',
              className: 'skipCol',
              visible: false
            },
            {
              title: generateSelectAllCheckbox(),
              data: null,
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              searchable: false,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(full);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              responsivePriority: 0,
              className: 'skipCol',
              width: 50,
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country Of Expertise',
              data: null,
              render: function (data, type, row) {
                return generateCountryCellForPayer(row.country.name, row.country.flag);
              }
            },
            {
              title: 'RPR Creation Date',
              data: 'created_datetime',
              type: 'date',
              render: function (data, type, row) {
                return data ? $filter('date')(new Date(data), CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE) : '';
              }
            },
            {
              title: 'No. of Months in RPR Network',
              data: 'months_since_joined_formatted'
            },
            {
              title: 'Days since Last Response',
              data: 'days_since_last_participation'
            },
            {
              title: 'Last Sent Assignment',
              data: 'last_assignment_sent_datetime',
              type: 'date',
              defaultContent: '',
              render: function (data, type, row) {
                return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_24H_TIME_NO_SEC);
              }
            },
            {
              title: 'Total Surveys Sent',
              data: 'assignments_sent_count'
            },
            {
              title: 'Total Surveys Accepted',
              data: 'assignments_accepted_count'
            },
            {
              title: '% Accepted',
              data: 'acceptance_percentage',
              render: function (data, type, row) {
                return data + '%';
              }
            },
            {
              title: 'Total Surveys Submitted',
              data: 'completed_survey_count'
            },
            {
              title: '% Responses',
              data: 'completion_percentage',
              render: function (data, type, row) {
                return data + '%';
              }
            },
            {
              title: '% Responded in Time',
              data: 'completion_on_time_percentage',
              render: function (data, type, row) {
                return data + '%';
              }
            },
            {
              title: 'Average Number of Days to Respond',
              data: 'avg_days_response'
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              data: null,
              className: 'center aligned skipCol',
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            },
            {
              title: '',
              data: 'description',
              className: 'skipCol',
              visible: false
            },
            {
              title: '',
              data: 'anonymized_profile',
              className: 'skipCol',
              defaultContent: '-',
              visible: false
            },
            {
              title: '',
              data: 'additional_information',
              className: 'skipCol',
              visible: false
            }]
        },
        {
          name: 'Assignment View',
          value: CONSTANTS.payersTableViews.ASSIGNMENT,
          order: [[3, 'asc']],
          columns: [
            {
              title: '',
              data: 'id',
              className: 'skipCol',
              visible: false
            },
            {
              title: generateSelectAllCheckbox(),
              data: null,
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              searchable: false,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(full);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              className: 'skipCol',
              responsivePriority: 0,
              width: 50,
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country Of Expertise',
              data: 'country',
              render: function (data, type, row) {
                return generateCountryCellForPayer(data.name, data.flag);
              }
            },
            {
              title: 'Surveys Invited to',
              data: 'surveys_assigned',
              render: generateProjectCodesWithToolTips
            },
            {
              title: 'Surveys Assigned to',
              data: 'surveys_assignment_sent',
              render: generateProjectCodesWithToolTips
            },
            {
              title: 'Surveys In Progress',
              data: 'surveys_in_progress',
              render: generateProjectCodesWithToolTips
            },
            {
              title: 'Clarification Requests Pending for',
              data: 'surveys_with_pending_clarification_requests',
              render: generateProjectCodesWithToolTips
            },
            {
              title: 'Total Surveys',
              data: 'total_surveys_with_payer',
              defaultContent: '-'
            },
            {
              title: 'Relevance - Last Survey',
              data: 'relevance_rating_last_survey',
              defaultContent: '-'
            },
            {
              title: 'Relevance - Average of Last 3 Surveys',
              data: 'relevance_rating_last_three_survey_avg',
              defaultContent: '-'
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              data: null,
              className: 'center aligned skipCol',
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            },
            {
              title: '',
              data: 'description',
              className: 'skipCol',
              visible: false
            },
            {
              title: '',
              data: 'anonymized_profile',
              className: 'skipCol',
              defaultContent: '-',
              visible: false
            },
            {
              title: '',
              data: 'additional_information',
              className: 'skipCol',
              visible: false
            }
          ]
        },
        {
          name: 'Honorarium and Agreement View',
          value: CONSTANTS.payersTableViews.HONORARIUM_AND_AGREEMENT_VIEW,
          columns: [
            {
              title: '',
              data: 'id',
              defaultContent: '',
              className: 'skipCol',
              visible: false
            },
            {
              title: generateSelectAllCheckbox(),
              data: null,
              className: 'center aligned skipCol payer-checkbox-cell',
              orderable: false,
              defaultContent: '',
              searchable: false,
              responsivePriority: 0,
              render: function (data, type, full, meta) {
                return generateCheckboxCellForPayer(full);
              }
            },
            {
              title: '',
              data: null,
              orderable: false,
              defaultContent: '',
              responsivePriority: 0,
              className: 'skipCol',
              width: 50,
              render: function (data, type, row) {
                return generateIconsCellForPayer(row);
              }
            },
            {
              title: 'Logged In',
              data: 'last_login',
              visible: false,
              render: function (data, type, row) {
                return row.last_login ? 'Yes' : 'No';
              }
            },
            {
              title: 'Welcome Invitation Sent',
              data: 'welcome_email_sent',
              visible: false,
              render: function (data, type, row) {
                return row.welcome_email_sent ? 'Yes' : 'No';
              }
            },
            {
              title: 'Locked/Unlocked',
              data: 'locked',
              visible: false,
              render: function (data, type, row) {
                return row.locked ? 'Locked' : 'Unlocked';
              }
            },
            {
              title: 'Active/Deactivated',
              data: 'active',
              visible: false,
              render: function (data, type, row) {
                return row.active ? 'Active' : 'Deactivated';
              }
            },
            {
              title: 'New Payer',
              data: 'new_payer',
              visible: false,
              render: function (data, type, row) {
                return row.new_payer ? 'Yes' : 'No';
              }
            },
            {
              title: 'Name',
              data: null,
              defaultContent: '',
              responsivePriority: 0,
              render: function (data, type, row) {
                return generateNameCellForPayer(row);
              }
            },
            {
              title: 'Respondent Type',
              data: null,
              responsivePriority: 0,
              defaultContent: '',
              render: function (data, type, row) {
                return generateNameForRespondentType(row);
              }
            },
            {
              title: 'Country of Residence',
              data: 'payer_country_of_residence',
              defaultContent: '',
              render: function (data, type, row) {
                return generateCountryCellForPayer(data.name, data.flag);
              }
            },
            {
              title: 'Email',
              defaultContent: '',
              data: 'email'
            },
            {
              title: 'Remuneration Rate',
              data: 'remuneration_rate',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                return data;
              }
            },
            {
              title: 'Remuneration Currency',
              data: 'remuneration_currency',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                return data;
              }
            },
            {
              title: 'Last Agreement Status',
              data: 'last_contract_details',
              defaultContent: '',
              render: function (data, type, row) {
                if (row.is_contract_bypassed_for_access) {
                  return 'Agreement Signing Bypassed';
                }
                if (!row.is_contract_sign_required && !data) {
                  return 'Agreement not initiated';
                }
                if (data && data.created_datetime >= row.last_contract_requested_datetime) { 
                  if (data.signed_datetime) {
                    return 'Signed';
                  }
                  else if (data.declined_datetime) {
                    return 'Declined';
                  }
                  else if (data.voided_datetime) {
                    return 'Voided';
                  }
                  else {
                    return 'Agreement Viewed and not Signed';
                  }
                }
                if (row.is_contract_sign_required) {
                  return 'Signing Required';
                }
              }
            },
            {
              title: 'Agreement Signed In',
              data: 'last_contract_details',
              defaultContent: '',
              render: function (data, type, row) {
                if (data && data.contract_source) {
                  return data.contract_source;
                }
                return 'Not Available';
              }
            },
            {
              title: 'Agreement Bypassed/Signed/Declined/Voided On',
              data: 'last_contract_details',
              type: 'date',
              defaultContent: '',
              render: function (data, type, row) {
                var event_datetime = null;

                if (row.contract_sign_bypassed_datetime) {
                  event_datetime = row.contract_sign_bypassed_datetime;
                }

                if (data && data.created_datetime >= row.last_contract_requested_datetime) { 

                  if (data.signed_datetime) {
                    event_datetime = data.signed_datetime;
                  }
                  else if (data.declined_datetime) {
                    event_datetime = data.declined_datetime;
                  }
                  else if (data.voided_datetime) {
                    event_datetime = data.voided_datetime;
                  }

                }

                if (event_datetime) {
                  return $filter('date')(
                    new Date(event_datetime),
                    CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE
                  );
                }
              }
            },
            {
              title: 'Agreement Bypassed By',
              data: 'contract_sign_bypassed_by',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                if (data) {
                  return data.first_name + ' ' + data.last_name;
                }
              }
            },
            {
              title: 'Agreement Bypassed Reason',
              data: 'contract_bypassed_reason',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                return data;
              }
            },
            {
              title: 'Agreement Declined Reason',
              data: 'last_contract_details',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                if (data && data.decline_reason && !row.contract_bypassed_reason
                  && data.declined_datetime > row.last_contract_requested_datetime) {
                  return data.decline_reason;
                }
                else {
                  return 'N/A';
                }
              }
            },
            {
              title: 'Terms of Use',
              data: 'terms_last_accepted_datetime',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                return $filter('date')(
                  data,
                  CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
              }
            },
            {
              title: 'Promotional Use of data',
              data: 'is_promotional_use_allowed',
              defaultContent: 'N/A',
              render: function (data, type, row) {
                if (data === true) {
                  return 'Allowed';
                }
                else if (data === false) {
                  return 'Not Allowed';
                }
              }
            },
            {
              title: 'Promotional Use Date',
              data: 'promotional_use_updated_datetime',
              defaultContent: '',
              type: 'date',
              render: function (data, type, row) {
                if (data) {
                  return $filter('date')(
                    new Date(data),
                    CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
                }
              }
            },
            {
              title: 'Do Not Contact',
              data: 'do_not_contact_reason_display'
            },
            {
              title: 'Pinned Notes',
              data: 'notes',
              render: renderPinnedNotes
            },
            {
              title: 'Actions',
              data: null,
              className: 'center aligned skipCol',
              orderable: false,
              searchable: false,
              responsivePriority: 1,
              render: function (data, type, row) {
                return fetchTableButtonsDropdown(row);
              }
            }
          ]
        }
      ];
    }]);
