app.service('portalHelper',
  ['SETTINGS', '$timeout', '$location', '$window', 'authHelper', '$ngConfirm', 'apiHelper', '$routeParams', 'INTERCOM',
    'HttpAuth', '$rootScope', '$filter', '$anchorScroll', 'CONSTANTS', '$compile', 'stringHelper', 'clientListService',
    'tor2Service', 'storageService',
    function (SETTINGS, $timeout, $location, $window, authHelper, $ngConfirm, apiHelper, $routeParams, INTERCOM,
      HttpAuth, $rootScope, $filter, $anchorScroll, CONSTANTS, $compile, stringHelper, clientListService,
      tor2Service, storageService) {

      var portalHelperService = this;

      portalHelperService.fix_undefined = function(x) {
        return (typeof x == 'undefined' ? '' : x);
      };

      portalHelperService.convertVWInputsToFloat= function(responses){

        _.map(responses, function(response){
          if (!response.van_westendorp_response){
            return;
          }
          response.van_westendorp_responses.acceptable = parseFloat(response.van_westendorp_responses.acceptable);
          response.van_westendorp_responses.expansive = parseFloat(response.van_westendorp_responses.expansive);
          response.van_westendorp_responses.too_expansive = parseFloat(response.van_westendorp_responses.too_expansive);
          response.van_westendorp_responses.prohibitive = parseFloat(response.van_westendorp_responses.prohibitive);
        });

        return responses;
      };

      var getOriginalAndShuffledSequence = function (table_responses_2, seed) {
        var row_numbers = _.pluck(table_responses_2, 'row');
        var originalSequence = (_.uniq(row_numbers)).sort(
          function (first, second) {
            return first-second;
          }
        );
        var shuffledSequence = angular.copy(originalSequence);
        shuffledSequence =  portalHelperService.shuffleArrayWithSeed(shuffledSequence, seed);
        return {
          'originalSequence': originalSequence,
          'shuffledSequence': shuffledSequence
        };
      };

      portalHelperService.randomizeTORInputs = function (table_responses_2, seed) {
        var result = getOriginalAndShuffledSequence(table_responses_2, seed);
        var originalSequence = result.originalSequence;
        var shuffledSequence = result.shuffledSequence;

        var rearranged_rows = [];
        for (var i = 0; i < originalSequence.length; i++) {
          var originalRows = angular.copy(table_responses_2);

          var rows_with_shuffled_index = _.filter(
            originalRows, {'row': shuffledSequence[i]}
          );

          _.map(rows_with_shuffled_index, function (item) {
            item.row = originalSequence[i];
          });

          rearranged_rows = rearranged_rows.concat(
            rows_with_shuffled_index
          );
        }

        return rearranged_rows;
      };

      portalHelperService.undoRandomizedTORInputs = function (table_responses_2, seed) {
        var result = getOriginalAndShuffledSequence(table_responses_2, seed);
        var originalSequence = result.originalSequence;
        var shuffledSequence = result.shuffledSequence;

        for (var i = 0; i < table_responses_2.length; i++) {
          var readIndex = table_responses_2[i].row;
          var actualIndex = shuffledSequence[
            originalSequence.indexOf(readIndex)
          ];
          table_responses_2[i].row = actualIndex;
        }

        return table_responses_2;
      };

      portalHelperService.arrangeRandomizedTOR2Inputs = function (responses) {
        _.map(responses, function(response){

          if (response.table_responses_2 && response.randomization_seed){
            portalHelperService.undoRandomizedTORInputs(
              response.randomizedTORInputRows,
              response.randomization_seed
            );
          }

          if (response.randomizedTORInputRows) {
            response.table_responses_2 = response.randomizedTORInputRows;
          }
        });

        return responses;
      };

      var randomizeRatingOptions = function (question, response, seed) {
        var randomizedOptions = [];
        if (question.randomize_options && seed) {
          var copyOfOptions = angular.copy(question.options);
          randomizedOptions = _.sortBy(copyOfOptions, 'option_value');
          portalHelperService.shuffleArrayWithSeed(randomizedOptions, seed);
          for (var i = 0; i < randomizedOptions.length; i++) {
            randomizedOptions[i].option_value = i + 1 ;
          }
        }
        else {
          randomizedOptions = question.options;
        }
        return randomizedOptions;
      };

      var randomizeSliderRatingOptions = function (question, response, seed) {
        var randomizedOptions = [];
        if (question.randomize_options && seed) {
          var copyOfOptions = angular.copy(question.options);
          randomizedOptions = _.sortBy(copyOfOptions, 'option_value');
          portalHelperService.shuffleArrayWithSeed(randomizedOptions, seed);
          for (var i = 0; i < randomizedOptions.length; i++) {
            randomizedOptions[i].option_value = i + 1 ;
          }
        }
        else {
          randomizedOptions = question.options;
        }
        return randomizedOptions;
      };

      var randomizeRadioRankingOptions = function (question, response, seed) {
        var randomizedOptions = [];
        if (question.randomize_options && seed) {
          var copyOfOptions = angular.copy(question.scale.options);
          randomizedOptions = _.sortBy(copyOfOptions, 'sequence_number');
          portalHelperService.shuffleArrayWithSeed(randomizedOptions, seed);
          for (var i = 0; i < randomizedOptions.length; i++) {
            randomizedOptions[i].sequence_number = i + 1 ;
          }
        } else {
          randomizedOptions = question.scale.options;
        }
        return randomizedOptions;
      };

      var randomizeDragAndDropRankingOptions = function (question, response, seed) {
        var randomizedOptions = [];
        var options = question.scale.options;
        if (response && response.ranking_responses_not_saved) {
          options = response.ranking_responses_not_saved;
        }

        if (question.randomize_options && seed) {
          var copyOfOptions = angular.copy(options);
          randomizedOptions = _.sortBy(copyOfOptions, 'option');
          portalHelperService.shuffleArrayWithSeed(randomizedOptions, seed);
          response.ranking_responses_not_saved = randomizedOptions;
        }
        else {
          randomizedOptions = options;
        }
        return randomizedOptions;
      };

      var randomizeTORGroupRows = function (question, response, seed) {
        var TYPE_OF_CELL = tor2Service.TYPE_OF_CELL;
        var INPUT_CELLS = [
          TYPE_OF_CELL.TEXT_BOX, TYPE_OF_CELL.CHECK_BOX, TYPE_OF_CELL.DROP_DOWN
        ];
        var randomizedOptions = [];
        if (question.randomize_options && seed) {
          var data = angular.copy(question.tor2_data.cells);
          outer: for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].length; j++) {
              var isInputCellFound = INPUT_CELLS.indexOf(data[i][j].type) >= 0;
              if (isInputCellFound) {
                var shuffledRows = data.splice(i, data.length);
                portalHelperService.shuffleArrayWithSeed(
                  shuffledRows, seed
                );
                shuffledRows = data.concat(shuffledRows);
                var tor2_data_copy = angular.copy(question.tor2_data);
                tor2_data_copy.cells = shuffledRows;
                randomizedOptions = tor2_data_copy;
                response.randomizedTORInputRows = portalHelperService.randomizeTORInputs(
                  response.table_responses_2,
                  seed
                );
                break outer;
              }
            }
          }
        }
        else {
          randomizedOptions = question.tor2_data;

          if (response && !response.randomizedTORInputRows) {
            response.randomizedTORInputRows = response.table_responses_2;
          }
        }
        return randomizedOptions;
      };

      portalHelperService.getRandomizedOptions = function (
        question,
        response,
        seed
      ) {

        if (question.type == CONSTANTS.questionTypeIds.RATING) {
          return randomizeRatingOptions(question, response, seed);
        }
        if (question.type == CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE) {
          return randomizeSliderRatingOptions(question, response, seed);
        }
        else if (question.type == CONSTANTS.questionTypeIds.RADIO_RANKING) {
          return randomizeRadioRankingOptions(question, response, seed);
        }
        else if (question.type == CONSTANTS.questionTypeIds.DRAG_DROP_RANKING) {
          return randomizeDragAndDropRankingOptions(question, response, seed);
        }
        else if (question.type == CONSTANTS.questionTypeIds.TABLE_OF_RESPONSES_2) {
          return randomizeTORGroupRows(question, response, seed);
        }
      };

      portalHelperService.is_randomization_allowed = function (question) {
        return [
          CONSTANTS.questionTypeIds.RATING,
          CONSTANTS.questionTypeIds.RADIO_RANKING,
          CONSTANTS.questionTypeIds.DRAG_DROP_RANKING,
          CONSTANTS.questionTypeIds.TABLE_OF_RESPONSES_2,
          CONSTANTS.questionTypeIds.SLIDER_RATING_SCALE
        ].indexOf(question.type) != -1;
      };

      portalHelperService.getRandomInt = function (min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      };

      portalHelperService.generate_random_id = function () {
        return Math.random().toString(36).substr(2, 9);
      };

      portalHelperService.getTitleCase = function (text) {
        var sentence = text.toLowerCase().split(' ');
        for (var i = 0; i< sentence.length; i++){
          sentence[i] = this.getSentenceCase(sentence[i]);
        }

        return sentence.join(' ');
      };

      portalHelperService.getSentenceCase = function (text) {
        return text[0].toUpperCase() + text.slice(1);
      };

      portalHelperService.fetchClientUserId = function (loginData) {

        if ($routeParams.clientUserId){
          return parseInt($routeParams.clientUserId);
        }
        else {
          var isClientPortal = $location.absUrl().indexOf('client/#/Client') !== -1;
          if (loginData != undefined && loginData.me != undefined && isClientPortal) {
            return loginData.me.id;
          }
        }

        return null;
      };


      portalHelperService.selectClientUserToCreateSurvey = function(clientUsers, $scope){

        var clientUserColumns = [
          {
            data: 'full_name',
            title: 'Client User'
          },
          {
            data: 'client_name',
            title: 'Client'
          },
          {
            data: 'client_code',
            title: 'Client ID'
          },
          {
            data: null,
            orderable: false,
            render: function(data, type, row){
              return '<button ng-click="setClientUserId('+ row.id +')" class="mat ui primary small wide button">Select</button>';
            }
          }
        ];

        var clientSelectionWindow = $ngConfirm({
          'title': 'Please select client user to associate this survey with:',
          'columnClass': 'large',
          'contentUrl': '../' + 'html_templates/dialogs/client_users_list.61002edd.html',
          'type': 'blue',
          'scope': $scope,
          onReady: function (scope) {
            scope.users_table = $('#client-users').DataTable({
              autoWidth: true,
              data: dataTableSafeData(clientUsers),
              ordering: true,
              order: [[0, 'asc']],
              pagingType: 'full_numbers',
              scrollX: false,
              columns: clientUserColumns,
              createdRow: function (row, data, index) {
                $compile(angular.element(row).contents())($scope);
              },
              initComplete: function (settings, json) {
                $timeout(function () {
                  angular.element('#' + $scope.tableId)
                    .DataTable()
                    .columns
                    .adjust()
                    .responsive
                    .recalc();
                  preventDataTableMenuClipping();

                  $scope.tableInitComplete = true;
                }, 500);
              }
            });
          }
        });

        $scope.setClientUserId = function(clientUserId){
          $scope.clientUserId = clientUserId;
          clientSelectionWindow.close();
        };

        return clientSelectionWindow;

      };

      portalHelperService.setupProjectCodeDetailsForUI = function (project) {
        project.client_code_part = '';
        project.licence_code_part = '';

        if (project.licence_code && project.licence_code.length > 5) {
          project.client_code_part = project.licence_code.substr(0, (project.licence_code.length - 2));
          project.licence_code_part = project.licence_code.substr(project.licence_code.length - 2);
        }

        return project;
      };

      portalHelperService.setupProjectCodeDetailsForAPI = function (project) {
        if (project.client_code_part != null && project.licence_code_part != null) {
          project.licence_code = [
            project.client_code_part,
            project.licence_code_part].join('');

          project.project_code = [
            project.licence_code,
            project.code,
            '_',
            project.topic
          ].join('');
        }

        return project;
      };

      portalHelperService.generateId = function (length) {
        var alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var id = '';
        var characters = [];

        for (var i = 0; i < length; i++) {
          var randomIndex = portalHelperService.getRandomInt(0, 62);
          characters.push(alphabet[randomIndex]);
        }

        id = characters.join('');
        return id;
      };

      portalHelperService.exportResponseStatuses = {
        'rejected': {
          'title': 'Payer Rejected Survey',
          'description': 'Payer has rejected the survey assignment.',
          'class': 'fa fa-times fa-fw red-icon'
        },
        'waiting_for_response': {
          'title': 'Waiting for survey response',
          'description': 'Payer has accepted the assignment but survey responses are in draft stage.',
          'class': 'fa fa-fw fa-clock-o orange-icon'
        },
        'waiting_for_admin_approval': {
          'title': 'Waiting for admin approval',
          'description': 'Payer has submitted responses but admin needs to approve them.',
          'class': 'fa fa-fw fa-exclamation-triangle red-icon'
        },
        'not_sent_clarification': {
          'title': 'Clarification not sent to payer as yet',
          'description': 'Admin has raised a clarification request but survey is not returned to payer.',
          'class': 'fa fa-fw fa-hourglass-o orange-icon'
        },
        'pending_clarification': {
          'title': 'Waiting for clarification response',
          'description': 'Survey has been returned to payer for clarification response.',
          'class': 'fa fa-fw fa-clock-o orange-icon'
        },
        'clarification_response_submitted': {
          'title': 'Clarification response submitted by Payer',
          'description': 'Payer has submitted clarification response.',
          'class': 'fa fa-fw fa-cloud-download blue-icon'
        },
        'response_approved': {
          'title': 'All responses approved',
          'description': 'All responses approved by admin.',
          'class': 'fa fa-fw fa-check-circle green-icon'
        },
        'expired': {
          'title': 'Survey assignment expired',
          'description': 'Payer has not responded even after extended end date.',
          'class': 'fa fa-fw fa-calendar red-icon'
        },
        'overdue': {
          'title': 'Overdue',
          'description': 'Payer has not responded before assignment end date.',
          'class': 'fa fa-fw fa-calendar red-icon'
        }
      };

      portalHelperService.getExportResponseStatus = function (status) {
        return portalHelperService.exportResponseStatuses[status];
      };

      portalHelperService.getUrl = function (relUrl) {
        return '../' + relUrl;
      };

      portalHelperService.prevent_dblclick = function () {
        return false;
      };

      portalHelperService.columnUserListTfaHidden = {
        data: null,
        className: 'skipCol',
        visible: false,
        render: function (data, type, row) {

          if (row.tfa_configured) {
            return 1;
          }

          if (row.tfa_mandatory) {
            return 2;
          }

          return 3;
        }
      };

      portalHelperService.columnUserListTfaVisible = {
        title: '2FA',
        data: null,
        orderData: [1],
        className: 'center aligned',
        render: function (data, type, row) {
          if ('export' === type) {
            if (row.tfa_configured) {
              return 'Active';
            }
            if (row.tfa_mandatory) {
              return 'Inactive (Mandatory)';
            }
            return 'Inactive';
          }

          if (row.tfa_configured) {
            return '<i class="fa fa-check-circle green-icon"></i>';
          }

          if (row.tfa_mandatory) {
            return '<i class="fa fa-dot-circle-o blue-icon"></i>';
          }

          return '<i class="fa fa-circle"></i>';
        }
      };

      portalHelperService.getGaborGrangerFirstPrice = function (q) {
        const price = q.pricing_model.display_decimal_points_in_pricing ? parseFloat(q.pricing_model.first_price).toFixed(1) : Math.trunc(q.pricing_model.first_price);
        if (q.pricing_model.use_percentage_instead_of_currency) {
          return price + '%';
        } else if (q.pricing_model.currency) {
          return q.pricing_model.currency + ' ' + price;
        }

        return price;
      };

      portalHelperService.formatGaborGrangerQuestionText = function(text, currency, price, displayDecimalPoints, pricingModel) {
        const locale = navigator.language || navigator.userLanguage;

        const formattedPrice = displayDecimalPoints
          ? parseFloat(price).toLocaleString(locale, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
          : Math.trunc(price).toLocaleString(locale);

        if (pricingModel.use_percentage_instead_of_currency) {
          return text.replace('#price#', `<span class="animate"> ${formattedPrice}%</span>`);
        }

        return text.replace('#price#', `<span class="animate">${currency} ${formattedPrice}</span>`);
      };

      this.getUrlRev = function (relUrl) {
        return portalHelperService.getUrl(relUrl);
      };

      portalHelperService.convert_to_local_time = function (utcTime, time_format) {
        if (utcTime != null) {
          if (!time_format) {
            return moment(utcTime)
              .local()
              .format(CONSTANTS.dateTimeFormatMoment.MEDIUM_24H_TIME_SEC);
          } else {
            return moment(utcTime).local().format(time_format);
          }
        }

        return '';
      };

      portalHelperService.scrollToElement = function (elem, offset, duration) {
        if (!duration) {
          duration = 500;
        }

        if (!offset) {
          offset = 0;
        }

        if ($(elem) != null && $(elem).offset() != null) {
          $([document.documentElement, document.body]).animate({
            scrollTop: $(elem).offset().top + offset
          }, duration);
        }
      };

      portalHelperService.getDisplayWidth = function () {
        if (screen.width && !window.devicePixelRatio) {
          return screen.width;
        }

        if (screen.width && window.devicePixelRatio) {
          return Math.round(screen.width * window.devicePixelRatio);
        }

        return 0;
      };

      portalHelperService.getDisplayHeight = function () {
        if (screen.height && !window.devicePixelRatio) {
          return screen.height;
        }

        if (screen.height && window.devicePixelRatio) {
          return Math.round(screen.height * window.devicePixelRatio);
        }

        return 0;
      };

      portalHelperService.showSurveyPopup = function (surveyId, countryId, screen_width, screen_height, popupHash) {
        if (portalHelperService.globalPopup && portalHelperService.globalPopup.opener) {
          portalHelperService.globalPopup.focus();
          return portalHelperService.globalPopup;
        }

        angular.element('html, body').css({
          overflow: 'hidden',
          height: '100%'
        });

        $('.navbar-nav li a').css('z-index', '2');
        $('.menu-container').css('z-index', '2');
        $('.overlay').css('display', 'block');

        if (!screen_width) {
          var popupWidth = portalHelperService.getDisplayWidth() * 0.85;
          var popupHeight = portalHelperService.getDisplayHeight() * 0.90;
        } else {
          popupWidth = screen_width * 0.85;
          popupHeight = screen_height * 0.90;
        }

        var options = 'toolbars=0, toolbar=no, location=no, scrollbars=yes, resizable=yes, top=150, left=150, width='
          + popupWidth + ', height=' + popupHeight;

        portalHelperService.globalPopup = portalHelperService.popup_window_open(($location.absUrl()
          .split('#')[0] + popupHash +
          surveyId + '/' + countryId + '?insidePopup=true'),
        'surveyPopup', options);

        return $timeout(function () {
          portalHelperService.globalPopup.onunload = function () {
            portalHelperService.globalPopup = null;
            angular.element('html, body').css({
              overflow: 'auto',
              height: 'auto'
            });

            $('.navbar-nav li a').css('z-index', 'auto');
            $('.overlay').css('display', 'none');
            $('.menu-container').css('z-index', '10');
          };
        }, 1000);
      };

      portalHelperService.reset_form_field = function (form, field_name) {
        var dirty_count = 0;
        angular.forEach(form, function (element, name) {
          if (name.indexOf('$') !== 0) {
            if (name == field_name) {
              element.$setPristine();
              element.$setUntouched();
            } else if (element.$dirty) {
              dirty_count += 1;
            }
          }
        });

        if (!dirty_count) {
          form.$setPristine();
          form.$setUntouched();
        }
      };

      portalHelperService.getFullAddress = function (user, country, detail_lists) {
        let payer_address = [];
        if (user.address) {
          payer_address = user.address.split('\n');
        }

        if (user.address_line1) {
          payer_address.push(user.address_line1);
        }

        if (user.address_line2) {
          payer_address.push(user.address_line2);
        }

        var lastAddressLine = '';
        if (user.address_line3) {
          lastAddressLine = user.address_line3;
        }
        if (user.address_line4) {
          lastAddressLine += ', ' + user.address_line4;
        }

        if (lastAddressLine.indexOf(', ') === 0) {
          lastAddressLine = lastAddressLine.substring(2);
        }

        if (lastAddressLine !== '') {
          lastAddressLine += ' ';
        }

        lastAddressLine += (user.postcode || '');

        if (lastAddressLine != '') {
          payer_address.push(lastAddressLine);
        }

        if (country && detail_lists && detail_lists.country_of_residence) {
          var country_name = $filter('filter')(
            detail_lists.country_of_residence,
            { 'id': country },
            true);
          if (country_name) {
            payer_address.push(country_name[0].name);
          }
        }

        return payer_address.join('<br />');
      };

      portalHelperService.get_clarification_count = function (response) {
        var count = 0;

        if (response.clarification_request) {
          count = 1;  
        }

        if (!response.clarification_response) {
          return count;
        }

        var histories = response.clarification_response.rejection_history;
        var historyDateResponses = [];

        histories.forEach(function (val) {
          if (val.clarification !== null) {
            count += 1;
          }

          if (!stringHelper.isNullOrEmpty(val.reason)) {
            count += 1;
          }

          if (!stringHelper.isNullOrEmpty(val.response_datetime)) {
            historyDateResponses.push(val.response_datetime);
          }
        });

        var lastPayerResponseDate = historyDateResponses.length > 0
          ? Math.max(historyDateResponses.map(function (t) {
            return new Date(t).getTime();
          }))
          : new Date(0).getTime();

        if (new Date(response.clarification_response.submission_datetime).getTime() > lastPayerResponseDate) {
          count += 1;
        }

        return count;
      };

      portalHelperService.toggle_rejection_body = function (clarId) {
        var headerDiv = $('#rejection-history-title-' + clarId);
        var bodyDiv = $('#rejection-history-body-' + clarId);

        if (bodyDiv.css('display') === 'none') {
          headerDiv.find('i').removeClass('fa-caret-down').addClass('fa-caret-up');
          headerDiv.find('.rejection-history-count').fadeOut();
          bodyDiv.slideDown();
        } else {
          $('#rejection-history-body-' + clarId + ' .reviewer-rejection:last').removeClass('border-glow-animation');

          headerDiv.find('i').removeClass('fa-caret-up').addClass('fa-caret-down');
          headerDiv.find('.rejection-history-count').fadeIn();
          bodyDiv.slideUp();
        }
      };

      portalHelperService.highlight_clarification = function (clarId, questionId) {

        var divToHighlight = $('#latest-request-' + clarId);
        portalHelperService.scrollToElement('#latest-request-' + clarId, -250);
        divToHighlight.addClass('border-glow-animation');

      };

      portalHelperService.is_last_payer_clarification = function (response) {
        if (!response.clarification_response) {
          return;
        }

        var dateOfSubmission = new Date(response.clarification_response.submission_datetime).getTime();
        var historyDateResponses = [];

        response.clarification_response.rejection_history.forEach(function (t) {
          if (!stringHelper.isNullOrEmpty(t.response_datetime)) {
            historyDateResponses.push(new Date(t.response_datetime).getTime());
          }
        });

        return historyDateResponses.length === 0 || dateOfSubmission > Math.max.apply(Math,
          historyDateResponses);
      };

      portalHelperService.validateNewPasswords = function (scope, password1, password2) {
        scope.error_list = [];

        var regex_special_char = /[!@#$%^&+=*<>?]/;
        var regex_digit = /[\d]+/;
        var regex_small_letter = /[a-z]+/;
        var regex_capital_letter = /[A-Z]+/;

        var all_regex_ok = true;

        if (scope.username && password1.indexOf(scope.username) !== -1) {
          scope.error_list.push('Password can\'t contain username');
          all_regex_ok = false;
        }

        if (!regex_special_char.test(password1)) {
          scope.error_list.push('Password must contain at least 1 special character');
          all_regex_ok = false;
        }

        if (!regex_digit.test(password1)) {
          scope.error_list.push('Password must contain at least 1 digit');
          all_regex_ok = false;
        }

        if (!regex_small_letter.test(password1)) {
          scope.error_list.push('Password must contain at least 1 lower case letter');
          all_regex_ok = false;
        }

        if (!regex_capital_letter.test(password1)) {
          scope.error_list.push('Password must contain at least 1 upper case letter');
          all_regex_ok = false;
        }

        if (!all_regex_ok) {
          return;
        }

        if (!scope.password_show) {
          if (!password2) {
            scope.error_list.push('Please confirm password');
            return;
          }

          if (password1 !== password2) {
            scope.error_list.push('Passwords do not match');
            return;
          }
        }

        return true;
      };

      portalHelperService.fixLeadingZero = function (number, digit_count) {
        var cur_number = '' + number;
        var zeroes = Array(digit_count + 1).join('0');
        return (zeroes + cur_number).slice(-digit_count);
      };

      portalHelperService.getNumberSuffix = function (i) {
        var j = i % 10,
          k = i % 100;
        if (j === 1 && k !== 11) {
          return 'st';
        }
        if (j === 2 && k !== 12) {
          return 'nd';
        }
        if (j === 3 && k !== 13) {
          return 'rd';
        }
        return 'th';
      };

      portalHelperService.getMaxUploadSize = function () {
        return (SETTINGS.MAX_UPLOAD_SIZE) ? SETTINGS.MAX_UPLOAD_SIZE : 20971520;
      };

      portalHelperService.popupDateOptionsAny = {
        maxDate: new Date(2050, 5, 22),
        minDate: new Date(1970, 1, 1),
        startingDay: 1,
        showWeeks: false
      };

      portalHelperService.truncateString = function (val, max_length) {
        if (val && val.length > max_length) {
          val = val.substr(0, max_length) + '...';
        }

        return val;
      };

      portalHelperService.getTemplateUrl = function (url) {
        return url;
      };

      portalHelperService.impersonateByAdmin = function (user_id) {
        var data = { 'user_id': user_id };
        var myDetails = portalHelperService.getMyDetails();
        authHelper.setLogin(myDetails, true);

        HttpAuth.post(apiHelper.getApiUrl() + '/auth/start_impersonation/', data)
          .then(
            function (response) {
              authHelper.setImpersonationData();
              authHelper.isLoggedIn().then(
                function (result) {
                  if (result) {
                    authHelper.setLogin(result.data, false);
                  }
                  authHelper.redirectLoggedIn();
                }
              );
            },
            portalHelperService.showErrorCommon
          );
      };

      portalHelperService.sendWelcomeEmail = function (userId, user_type_name, cb_success) {
        var url = apiHelper.getApiUrl() + '/api/admin/user/reset_password/' + userId + '.json';
        HttpAuth.put(url, {}).then(
          function (result) {
            portalHelperService.showPopup('Email Sent!',
              'A welcome email has been sent to the ' + user_type_name,
              cb_success);
          },
          portalHelperService.showErrorCommon
        );
      };

      var generatePasswordAndWelcomeEmailLinks = function(apiUrl, waitMessage, popupTitle,
        popupMessage, cb_success) {

        portalHelperService.showToast(CONSTANTS.typeOfToasts.INFO, waitMessage);

        HttpAuth.put(apiUrl, {}).then(
          function (result) {
            var url = result.data.link;
            portalHelperService.showCopyableTextPopup(popupTitle, popupMessage, url, cb_success);
          },
          function (result) {
            if (portalHelperService.responseContains(result, 'unlock the user')) {
              portalHelperService.showPopupError('Error', result.data[0]);
            } else {
              portalHelperService.showErrorCommon(result);
            }
          }
        );
      };

      portalHelperService.passwordResetAsAdmin = function (userId, user_type_name, cb_success) {
        var url = apiHelper.getApiUrl() + '/api/admin/user/reset_password/' + userId + '.json';
        HttpAuth.put(url, {}).then(
          function (result) {
            portalHelperService.showPopup('Email Sent!',
              'A password reset email has been sent to the ' + user_type_name,
              cb_success);
          },
          function (result) {
            if (portalHelperService.responseContains(result, 'unlock the user')) {
              portalHelperService.showPopupError('Error', result.data[0]);
            } else {
              portalHelperService.showErrorCommon(result);
            }
          }
        );
      };

      portalHelperService.bulkPasswordResetAsAdmin = function (userIds, user_type_name, cb_success) {
        var url = apiHelper.getApiUrl() + '/api/admin/user/reset_password.json';
        HttpAuth.post(url, { users: userIds }).then(
          function (result) {
            let popupContent = '<p>' + result.data.success + ' Emails sent successfully, ' +
              result.data.omit + ' omitted and ' + result.data.errors.length + ' errors </p>';

            if (result.data.errors.length) {
              popupContent += '<p>Errors are: <br>';
              result.data.errors.forEach((error, index) => {
                popupContent += (index + 1) + ') ' + error + '<br>';
              });
              popupContent += '</p>';
            }

            portalHelperService.showPopup('Alert', popupContent, cb_success, 'blue');
          },
          function (result) {
            portalHelperService.showErrorCommon(result);
          }
        );
      };

      portalHelperService.passwordResetLinkAsAdmin = function (userId, user_type_name, cb_success) {
        var apiUrl = apiHelper.getApiUrl() + '/api/admin/user/reset_password_link/' + userId + '.json';
        var waitMessage = 'We are generating the password reset link. Please wait...';
        var popupTitle = 'Reset Password Link Generated';
        var popupMessage = 'The reset password link is:';

        return generatePasswordAndWelcomeEmailLinks(apiUrl,
          waitMessage,
          popupTitle,
          popupMessage,
          cb_success);
      };

      portalHelperService.showCopyableTextPopup = function (title, message, copyableText, callback) {
        var messageBoxHtml = [
          message + '<br />',
          '<input ' +
          'readonly="readonly"',
          'onclick="this.setSelectionRange(0, this.value.length)"',
          'class="wide mt-3"',
          'type="text"',
          'value="' + copyableText + '"/>',
          '<div class="mt-2">',
          '<a href="javascript:" onclick="return copyToClipboard(\'' + copyableText + '\')">Copy to Clipboard</a>',
          '</div>'
        ].join('\n');

        portalHelperService.showPopup(title, messageBoxHtml, callback);
      };

      var removeTfaForUser = function (user, cb_success) {
        HttpAuth.post(apiHelper.getApiUrl() + '/auth/remove-tfa/', { 'user_id': user.id }).then(
          function (result) {
            user.tfa_configured = false;

            if (cb_success) {
              cb_success();
            }

            portalHelperService.showPopupOK('Success', 'Your changes are successful.');
          }, function (response) {
            portalHelperService.showErrorCommon(response);
          }
        );
      };

      portalHelperService.getUTCDate = function (dateStr) {
        const localDate = new Date(dateStr);
        const utcYear = localDate.getUTCFullYear();
        const utcMonth = localDate.getUTCMonth();
        const utcDay = localDate.getUTCDate();
        const utcHours = 0;
        const utcMinutes = 0;
        const utcSeconds = 0;

        const utcDate = new Date(utcYear, utcMonth, utcDay, utcHours, utcMinutes, utcSeconds);
        return utcDate;
      };

      portalHelperService.removeTfaConfirm = function (user, cb_success) {
        var title = 'Are you sure?';
        var message = 'Remove TFA for the user?';

        portalHelperService.showPopupYesNo(
          title,
          message,
          function () {
            removeTfaForUser(user, cb_success);
          },
          null,
          'Yes',
          'Cancel',
          null
        );
      };

      var setLockStatusForUsers = function (users, lockStatus) {
        var url = apiHelper.getApiUrl() + '/api/admin/user/lock.json';
        var data = {
          'locked': lockStatus,
          'users': users
        };

        return HttpAuth.post(url, data);
      };

      var setActiveStatusForUsers = function (users, activeFlag) {
        var url = apiHelper.getApiUrl() + '/api/admin/user/active.json';
        var data = {
          'active': activeFlag,
          'users': users
        };

        return HttpAuth.post(url, data);
      };

      var showUpdateUIForUsers = function (users, flag) {
        var showUpdateUIForUsersPromise = new Promise(function(resolve, reject){
          var updateActiveStatus = flag == CONSTANTS.updateUsersFlagTypes.ACTIVE ? true : false;

          var activeUsers = _.filter(users, function(user) {
            return updateActiveStatus ? user.active : !user.locked;
          });

          var multi_users = activeUsers.length > 1 ? true : false;
          var config = {
            active: {
              buttonText: 'Deactivate',
              title: multi_users ? 'Deactivate Payers ?' : 'Deactivate Payer'
            },
            lock: {
              buttonText: 'Lock',
              title: multi_users ? 'Lock Payers ?' : 'Lock Payer'
            }
          };

          var popUpScope = $rootScope.$new(true);
          popUpScope.reason = '';
          popUpScope.is_same_reason = multi_users;
          popUpScope.flag = flag;
          popUpScope.is_input_invalid = false;
          popUpScope.constants = {
            'REASON' : 'reason',
            'IS_SAME_REASON' : 'is_same_reason'
          };

          popUpScope.change_callback = function(key, value) {
            if (popUpScope.constants.REASON == key || popUpScope.constants.IS_SAME_REASON == key) {
              popUpScope[key] = value;
            } else {
              popUpScope.users[key].reason = value;
            }
          };

          popUpScope.users = _.map(activeUsers, function (user, index) {
            var payerName = user.full_name;

            if (payerName == '' || payerName == null) {
              payerName = user.first_name + ' ' + user.last_name;
            }

            if (payerName == ' ' || payerName == '' || payerName == null) {
              payerName = 'Payer ' + (index + 1);
            }

            var activeSurveys = _.map(user.live_surveys, function (survey) {
              return 'MAT_' + survey;
            }).join(', ');

            return {
              'id' : user.id,
              'full_name': payerName,
              'surveys': activeSurveys,
              'reason' : ''  
            };
          });
          $ngConfirm({
            title: config[flag].title,
            contentUrl: '../html_templates/payers_change_status.427b121e.html',
            scope: popUpScope,
            columnClass: 'col-md-10',
            type: 'orange',
            closeIcon: true,
            escapeKey: true,
            backgroundDismiss: true,
            buttons: {
              Cancel: {
                btnClass: 'mat ui primary button'
              },
              button: {
                text: config[flag].buttonText,
                btnClass: 'mat ui critical button',
                action: function() {
                  if (popUpScope.is_same_reason) {
                    if (popUpScope.reason.trim() == '') {
                      popUpScope.is_input_invalid = true;
                      return false; 
                    }

                    popUpScope.users = _.map(popUpScope.users, function(user) {
                      user.reason = popUpScope.reason;
                      return user;
                    });
                  } else {
                    popUpScope.is_input_invalid = _.reduce(popUpScope.users, function(preVal, user) {
                      return preVal || !user.reason || user.reason.trim() == '';
                    }, false);
                  }

                  if (popUpScope.is_input_invalid) {
                    return false;
                  }
                  var userIds =  _.map(popUpScope.users, function(user) {
                    return {'id' : user.id, 'reason' : user.reason};
                  });

                  if (updateActiveStatus) {
                    return setActiveStatusForUsers(userIds, false).then(resolve, reject);
                  }

                  return setLockStatusForUsers(userIds, true).then(resolve, reject);
                }
              }
            }
          });
        });
        return showUpdateUIForUsersPromise;
      };
      portalHelperService.update_lock_status_for_users = function (users, lockStatus, arePayers) {
        var proimseSuccess = function(result) {
          var strActiveStatus = lockStatus ? 'Locked' : 'Unlocked';
          var message = 'The user has been ' + strActiveStatus.toLowerCase() + ' successfully';
          var title = strActiveStatus;

          if (users.length >= 1) {
            message = 'All selected payers have been ' + strActiveStatus.toLowerCase() + '.';
          }

          portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, message, title);
          return result;
        };

        var promiseFailure = function(error) {
          portalHelperService.showErrorCommon(error);
          throw error;
        };

        if (lockStatus && arePayers) {
          return showUpdateUIForUsers(users, CONSTANTS.updateUsersFlagTypes.LOCK).then(proimseSuccess, promiseFailure);
        }

        var userIds = _.map(users, function (user) {
          return {'id' : user.id};
        });

        return setLockStatusForUsers(userIds, lockStatus).then(proimseSuccess, promiseFailure);
      };

      portalHelperService.lockClientAsAdmin = function (user) {
        var data = { 'locked': !user.locked };
        var url = apiHelper.getApiUrl() + '/api/admin/client/locked/' + user.id + '.json';

        HttpAuth.put(url, data).then(
          function (result) {
            user.locked = !user.locked;

            var message = user.locked
              ? 'Client has been locked successfully'
              : 'Client has been unlocked successfully';

            var title = user.locked
              ? 'Locked'
              : 'Unlocked';

            portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, message, title);
          },
          function (result) {
            portalHelperService.showErrorCommon(result);
          }
        );
      };

      portalHelperService.activateClientAsAdmin = function (client) {
        var data = { 'active': !client.active };
        var url = apiHelper.getApiUrl() + '/api/admin/client/active/' + client.id + '.json';

        HttpAuth.put(url, data).then(
          function (result) {
            client.active = !client.active;

            var message = client.active
              ? 'Client has been activated successfully'
              : 'Client has been deactivated successfully';

            var title = client.active
              ? 'Activated'
              : 'Deactivated';

            portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, message, title);
          },
          function (result) {
            portalHelperService.showErrorCommon(result);
          }

        );
      };

      portalHelperService.update_active_status_for_users = function (users, activeFlag, arePayers) {
        var proimseSuccess = function(result) {
          var strActiveStatus = activeFlag ? 'Activated' : 'Deactivated';
          var message = 'The user has been ' + strActiveStatus.toLowerCase() + ' successfully';
          var title = strActiveStatus;

          if (users.length >= 1) {
            message = 'All selected payers have been ' + strActiveStatus.toLowerCase() + '.';
          }

          portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, message, title);

          return result;
        };

        var promiseFailure = function(error) {
          portalHelperService.showErrorCommon(error);
          throw error;
        };

        if (arePayers && activeFlag == false) {
          return showUpdateUIForUsers(users, CONSTANTS.updateUsersFlagTypes.ACTIVE).then(proimseSuccess, promiseFailure);
        }

        var userIds = _.map(users, function (user) {
          return {'id' : user.id};
        });
        return setActiveStatusForUsers(userIds, activeFlag).then(proimseSuccess, promiseFailure);
      };

      portalHelperService.getFileIconPath = function (file_name) {
        if (file_name.match(/.pdf$/i)) {
          return portalHelperService.getUrl('Images/pdf.png');
        } else if (file_name.match(/.xlsx$/i) || file_name.match(/.xls$/i)) {
          return portalHelperService.getUrl('Images/excel.png');
        } else if (file_name.match(/.doc$/i) || file_name.match(/.docx$/i)) {
          return portalHelperService.getUrl('Images/word.png');
        } else if (file_name.match(/.ppt$/i) || file_name.match(/.pptx$/i)) {
          return portalHelperService.getUrl('Images/ppt.png');
        }

        return './Images/excel.png';
      };

      portalHelperService.getIconClassFromFilename = function (file_name) {
        if (file_name.match(/.pdf$/i)) {
          return 'fa fw fa-file-pdf-o';
        } else if (file_name.match(/.xlsx$/i) || file_name.match(/.xls$/i)) {
          return 'fa fw fa-file-excel-o';
        } else if (file_name.match(/.doc$/i) || file_name.match(/.docx$/i)) {
          return 'fa fw fa-file-word-o';
        } else if (file_name.match(/.ppt$/i) || file_name.match(/.pptx$/i)) {
          return 'fa fw fa-file-powerpoint-o';
        }

        return 'fa fw fa-file-text-o';
      };

      portalHelperService.meSharedReady = function (cb_success) {
        portalHelperService.retrieveMeShared(function (data) {
          cb_success();
        });
      };

      portalHelperService.retrieveMeShared = function (cb_success, useOrigLogin, forceRetrieval, cb_error) {
        if (!forceRetrieval) {
          var meShared = (useOrigLogin) ? authHelper.getMeSharedOrig() : authHelper.getMeShared();
          if (angular.isDefined(meShared) && angular.isDefined(meShared.email)) {
            cb_success(authHelper.getMeShared());
            return;
          }
        }

        var url_me = apiHelper.getApiUrl() + '/api/mydetails/';
        var config = { 'skip_impersonation': !!(useOrigLogin) };

        HttpAuth.get(url_me, config).then(function (result) {
          authHelper.setMeShared(result.data);
          cb_success(result.data);
        }, function (result) {
          if (cb_error) {
            cb_error(result.data);
          }
        });
      };

      portalHelperService.getQuestionNumber = function (question_id, sections, questions, filterQuestionsByCountry) {
        var questions_total = 0;

        for (var i0 = 0; i0 < sections.length; i0++) {
          var group = sections[i0];
          var group_questions = $filter('filter')(questions, { 'section': group.id });

          if (filterQuestionsByCountry) {
            group_questions = $filter('filter')(questions, filterQuestionsByCountry);
          }

          for (var i1 = 0; i1 < group_questions.length; i1++) {
            questions_total += 1;
            var q = group_questions[i1];

            if (q.id == question_id ||
              q.question_id == question_id 
            ) {
              return questions_total;
            }
          }
        }

        return questions_total;
      };

      portalHelperService.responseContains = function (result, text) {
        return JSON.stringify(result.data).indexOf(text) !== -1;
      };

      portalHelperService.MAX_DROPDOWN_OPTION_LENGTH = 250;

      portalHelperService.getDateOnlyFormat = function () {
        return 'dd MMM yyyy';
      };

      portalHelperService.countryCode = { UK: 77, US: 233, Canada: 38 };
      portalHelperService.otherOptions = { NO_OTHER: 0, SINGLE_OTHER: 1, MULTIPLE_OTHER: 2 };

      portalHelperService.scaleTypeIds = {
        RATING: 1,
        RANKING: 2,
        SLIDER_RATING: 5
      };

      portalHelperService.questionTypes = {
        1: 'Free Text',
        2: 'Dropdown List',
        3: 'Radio Buttons',
        4: 'Check Boxes',
        5: 'Rating Scale',
        6: 'Drag and Drop Ranking',
        8: 'Radio Button Ranking',
        9: 'Points Allocation',
        10: 'Van Westendorp Pricing Model',
        11: 'Gabor-Granger',
        12: 'Table of Responses',
        13: 'Slider Rating Scale'
      };

      portalHelperService.questionTypeBackend = {
        1: 'free_text',
        2: 'dropdown_list',
        3: 'radio_buttons',
        4: 'check_boxes',
        5: 'rating_scale',
        6: 'ranking_scale',
        8: 'radio_ranking_scale',
        9: 'points_allocation',
        10: 'table_of_responses_2',
        11: 'slider_rating_scale'
      };

      portalHelperService.orderedQuestionTypes = [
        [1, portalHelperService.questionTypes[1]],
        [2, portalHelperService.questionTypes[2]],
        [3, portalHelperService.questionTypes[3]],
        [4, portalHelperService.questionTypes[4]],
        [5, portalHelperService.questionTypes[5]],
        [6, portalHelperService.questionTypes[6]],
        [8, portalHelperService.questionTypes[8]],
        [12, portalHelperService.questionTypes[12]],
        [9, portalHelperService.questionTypes[9]],
        [10, portalHelperService.questionTypes[10]],
        [11, portalHelperService.questionTypes[11]],
        [13, portalHelperService.questionTypes[13]]
      ];

      portalHelperService.multiChoiceQuestions = [2, 3, 4];
      portalHelperService.scaleQuestions = [5, 6, 8, 13];

      var surveyStatusNamePairs = [
        [CONSTANTS.surveyStatuses.SCOPING, 'Scoping'],
        [CONSTANTS.surveyStatuses.QUESTION_DEVELOPMENT, 'Question Development'],
        [CONSTANTS.surveyStatuses.COMPLIANCE, 'Compliance'],
        [CONSTANTS.surveyStatuses.FIELDING_AND_ANALYSIS, 'Fielding And Analysis'],
        [CONSTANTS.surveyStatuses.FOLLOW_UP, 'Follow Up'],
        [CONSTANTS.surveyStatuses.DELIVERED, 'Delivered'],
        [CONSTANTS.surveyStatuses.ON_HOLD, 'On Hold'],
        [CONSTANTS.surveyStatuses.CANCELLED, 'Cancelled'],
        [CONSTANTS.surveyStatuses.DELETED, 'Deleted']
      ];

      portalHelperService.surveyStatusNames = {};
      surveyStatusNamePairs.forEach(function (pair) {
        portalHelperService.surveyStatusNames[pair[0]] = pair[1];
      });

      var responseStatusNamePairs = [
        [CONSTANTS.responseStatuses.DRAFT, 'Draft'],
        [CONSTANTS.responseStatuses.SUBMITTED, 'Submitted'],
        [CONSTANTS.responseStatuses.APPROVED, 'Approved']
      ];

      portalHelperService.responseStatusNames = {};
      responseStatusNamePairs.forEach(function (pair) {
        portalHelperService.responseStatusNames[pair[0]] = pair[1];
      });

      portalHelperService.getReadableSurveyStatusName = function (status, onHold) {
        return onHold ? 'On Hold' : portalHelperService.surveyStatusNames[status];
      };

      portalHelperService.getErrors = function (response) {
        if (response.data.non_field_errors && response.data.non_field_errors.length > 0) {
          portalHelperService.scrollToElementById('error_list');
          return response.data.non_field_errors;
        }

        portalHelperService.showErrorCommon(response);
      };

      portalHelperService.handle_status_404 = function(scope){

        var popupHandle404 = $ngConfirm({
          title: '',
          contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/404_not_found.4b80166a.html'),
          type: 'red',
          columnClass: 'col-md-8',
          closeIcon: true,
          escapteKey: true,
          scope: scope,
          onOpen: function(scope){
            scope.closeMe = function(){
              popupHandle404.close();
            };
          },
          buttons: {
            Close: {
              btnClass: 'mat ui primary button'
            }
          }
        });

      };

      portalHelperService.handle_status_400 = function(scope){

        var popupHandle400 = $ngConfirm({
          title: '',
          contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/400_bad_request.635da228.html'),
          type: 'red',
          columnClass: 'medium',
          closeIcon: true,
          escapeKey: true,
          scope: scope,
          onOpen: function(scope) {
            scope.closeMe = function(){
              popupHandle400.close();
            };
          },
          buttons: {
            Close: {
              btnClass: 'mat ui primary button'
            }
          }
        });

      };

      portalHelperService.showErrorCommon = function (response) {
        toastr.clear();

        if (response.data && !Array.isArray(response) &&
          response.data.detail &&
          response.data.detail.indexOf('Only allowed for') !== -1) {
          authHelper.redirectLoggedIn();
          return;
        }

        var scope = $rootScope.$new(true);
        scope.error_list = [];
        scope.status_code = '';
        scope.response = response;
        if (response && !Array.isArray(response) && response.status == -1 && 'abort' != response.xhrStatus) {

          if (portalHelperService.no_internet_popup) {
            return;
          }

          portalHelperService.no_internet_popup = $ngConfirm({
            title: 'No internet',
            content: 'Please check your internet connection',
            type: 'red',
            scope: scope,
            buttons: {
              OK: {
                btnClass: 'mat ui critical button',
                action: function () {
                  portalHelperService.no_internet_popup = null;
                }
              }
            }
          });
          return;
        }

        if (404 === response.status){
          portalHelperService.handle_status_404(scope);
          return;
        }

        if (400 === response.status){
          portalHelperService.handle_status_400(scope);
          return;
        }


        if (response && Array.isArray(response)) {
          for (var i = 0; i < response.length; i++) {
            if (Array.isArray(response[i].data)) {
              scope.error_list = scope.error_list.concat(response[i].data);
            }

            if (!scope.status_code && response[i].status != 200) {
              scope.status_code = response[i].status;
            }
          }
        } else if (response.data) {
          if (Array.isArray(response.data)) {
            scope.error_list = response.data;
          } else if (response.data.detail) {
            scope.error_list = [response.data.detail];
          } else {
            scope.error_list = [JSON.stringify(response.data)];
          }
          scope.status_code = response.status;
        }

        if ('status_code' in scope) {
          $ngConfirm({
            title: 'Something went wrong',
            contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/error_common.6c729c11.html'),
            type: 'red',
            scope: scope,
            buttons: {
              OK: {
                btnClass: 'mat ui critical button'
              }
            }
          });
        }

      };

      portalHelperService.isMultiRationale = function (rationale_type) {
        return rationale_type == 2;
      };

      portalHelperService.isSingleRationale = function (rationale_type) {
        return rationale_type == 1;
      };

      portalHelperService.prepareDownloadUrl = function (file_rel_url, query_args) {
        var url_file = apiHelper.getApiUrl() + file_rel_url;

        if (query_args) {
          url_file += '?';
          for (var arg_name in query_args) {

            if (Array.isArray(query_args[arg_name])) {
              for (var i = 0; i < query_args[arg_name].length; i++) {
                url_file += '&' + arg_name + '=' + query_args[arg_name][i];
              }
            } else {
              url_file = url_file + '&' + arg_name + '=' + query_args[arg_name];
            }
          }
        }

        return url_file;
      };

      portalHelperService.downloadFileWithUrl = function (url_file, query_args) {
        var downloadUrl = portalHelperService.prepareDownloadUrl(
          url_file, query_args
        );
        window.open(downloadUrl, '_blank');
      };

      portalHelperService.downloadFile = function (url_file, query_args) {
        var downloadUrl = portalHelperService.prepareDownloadUrl(
          url_file, query_args
        );
        return HttpAuth.get(downloadUrl);
      };

      portalHelperService.downloadReport = function (report_id) {
        var downloadUrl = portalHelperService.prepareDownloadUrl(
          '/api/surveyreport/' + report_id + '.json'
        );
        window.open(downloadUrl, '_blank');
      };

      portalHelperService.admin_export_excel_confirm = function ($scope, survey_id) {
        var url_order = apiHelper.getApiUrl() + '/reports/api/admin/survey/response/order/' + survey_id + '.json';
        $scope.dialog = {};

        HttpAuth.get(url_order).then(
          function (result) {
            var cur_scope = $rootScope.$new(true);
            cur_scope.response_order = result.data;

            cur_scope.response_sortable = {
              orderChanged: function (event) {
                for (var i = 0; i < cur_scope.response_order.responses.length; i++) {
                  cur_scope.response_order.responses[i]['export_order'] = i + 1;
                }
              }
            };

            $scope.dialog = $ngConfirm({
              title: 'Change order',
              contentUrl: portalHelperService.getUrlRev(
                'html_templates/dialogs/admin_export_response_order.d3457f68.html'),
              columnClass: 'medium',
              scope: cur_scope,
              buttons: {
                Save: {
                  btnClass: 'mat ui primary button',
                  text: 'Save order and export',
                  action: function (scope, button) {
                    HttpAuth.put(url_order, scope.response_order).then(function (result) {
                      $scope.dialog.close();
                      $scope.export_excel();
                    }, function (result) {
                      portalHelperService.showErrorCommon(result);
                    });
                  }
                },
                Export: {
                  btnClass: 'mat ui primary button',
                  text: 'Export',
                  action: function (scope, button) {
                    $scope.export_excel();
                  }
                },
                Cancel: {
                  btnClass: 'mat ui critical button',
                  action: function (scope, button) {
                  }
                }
              }
            });
          },
          function (result) {
            portalHelperService.showErrorCommon(result);
          }
        );
      };

      portalHelperService.getQuestionTypeName = function (type) {
        return portalHelperService.questionTypes[type];
      };

      portalHelperService.getQuestionTypeNameBackend = function (type) {
        return portalHelperService.questionTypeBackend[type];
      };

      portalHelperService.setupInfoIconTooltips = function (tooltipClassName, isHtml) {
        var className = tooltipClassName + ':not(\'.tooltipstered\')';
        var obj = $(className);
        if (obj && obj.tooltipster) {
          obj.tooltipster({
            contentAsHTML: (isHtml === true),
            delay: 0,
            animation: 'grow'
          });
        }
      };

      portalHelperService.isFreeTextQuestion = function (question_type) {
        if (question_type == CONSTANTS.questionTypeIds.FREE_TEXT) {
          return true;
        }
      };

      portalHelperService.isMultiChoiceQuestion = function (question_type) {
        if (portalHelperService.multiChoiceQuestions.indexOf(question_type) !== -1) {
          return true;
        }
      };

      portalHelperService.isRatingScaleQuestion = function (question_type) {
        if (question_type == 5) {
          return true;
        }
      };

      portalHelperService.isPointsQuestion = function (question_type) {
        if (question_type == 9) {
          return true;
        }
      };

      portalHelperService.isVanWestendorp = function (question_type) {
        if (question_type == CONSTANTS.questionTypeIds.VAN_WESTENDORP_PRICING_MODEL) {
          return true;
        }
      };

      portalHelperService.isScaleQuestion = function (question_type) {
        if (portalHelperService.scaleQuestions.indexOf(question_type) !== -1) {
          return true;
        }
      };

      portalHelperService.checkTfaRequired = function (me) {
        return !!(me.tfa_mandatory && !me.tfa_configured);
      };

      portalHelperService.redirectTfaRequired = function () {
        $window.location.href = '../login/#/TfaRequired';
      };

      var sendPayerToContractPage = function () {
        var urlsAllowedBeforeSigningContract = [
          '/forgotten/password',
          '/forgotten/username',
          '/reset',
          '/firstpassword',
          '/tfarequired',
          '/tfasetupintro',
          '/tfasetup',
          '/payers/contractsign',
          'payers/payerexpertiseform'
        ];
        var isUrlAccessAllowedBeforeContract = false;
        var url = $location.url().toLowerCase();
        for (var i = 0; i < urlsAllowedBeforeSigningContract.length; i++) {
          isUrlAccessAllowedBeforeContract = url.indexOf(urlsAllowedBeforeSigningContract[i]) !== -1;
          if (isUrlAccessAllowedBeforeContract){
            break;
          }
        }
        if (!isUrlAccessAllowedBeforeContract) {
          authHelper.redirectLoggedIn('/Payers/ContractSign');
        }
      };

      const sendPayerToTermsOfUsePage = function () {
        const urlsAllowedBeforeTermsCheck = [
          'payers/payerexpertiseform'
        ];
        var isUrlAccessAllowedBeforeTermsCheck = false;
        const url = $location.url().toLowerCase();
        for (var i = 0; i < urlsAllowedBeforeTermsCheck.length; i++) {
          isUrlAccessAllowedBeforeTermsCheck = url.indexOf(urlsAllowedBeforeTermsCheck[i]) !== -1;
          if (isUrlAccessAllowedBeforeTermsCheck){
            break;
          }
        }
        if (!isUrlAccessAllowedBeforeTermsCheck) {
          authHelper.redirectLoggedIn('/Payers/terms-of-use');
        }
      };

      portalHelperService.portalReady = function (cb_success, is_contract_sign_required) {
        return authHelper.getLoginDetails().then(
          function (result) {
            var myDetails = result;
            if (myDetails.is_contract_sign_required && myDetails.docusign_enabled) {
              sendPayerToContractPage();
            }
            else if (myDetails.show_terms) {
              sendPayerToTermsOfUsePage();
            }
            if (cb_success) {
              cb_success();
            }
            return myDetails;
          },
          function (error) {
            if ('abort' != error.xhrStatus) {
              portalHelperService.showErrorCommon(error);
            }
          }
        );
      };

      portalHelperService.highlightAndScrollToElementById = function (id) {
        this.scrollToElementById(id);

        var element = angular.element('#' + id);
        element.addClass('border-glow-animation');
        setTimeout(function () {
          element.removeClass('border-glow-animation');
        }, 4000);
        return element;
      };

      portalHelperService.scrollTo = function (id, yOffset) {
        $timeout(function () {
          $anchorScroll.yOffset = (yOffset) ? yOffset : 0;
          if (id) {
            $anchorScroll(id);
          } else {
            $anchorScroll();
          }
        });
      };
      portalHelperService.scrollToElementById = function (id, scrollOptions) {
        var defaultOptions = {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        };

        var elems = angular.element('#' + id);
        if (elems && elems.length > 0) {
          elems[0].scrollIntoView(scrollOptions || defaultOptions);
        }
      };

      portalHelperService.scrollToElementByClassName = function (cls, scrollOptions) {
        var defaultOptions = {
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        };

        var elems = angular.element(cls);
        if (elems && elems.length > 0) {
          elems[0].scrollIntoView(scrollOptions || defaultOptions);
        }
      };

      portalHelperService.scrollToTop = function () {
        $timeout(function () {
          $anchorScroll();
        });
      };

      portalHelperService.getDaysRemaining = function (date_string) {
        return Math.round(((new Date(date_string)).getTime() - (new Date()).getTime()) / 1000 / 3600 / 24);
      };

      portalHelperService.getHoursRemaining = function (date_string) {
        return Math.round(((new Date(date_string)).getTime() - (new Date()).getTime()) / 1000 / 3600);
      };

      portalHelperService.getSecondsRemaining = function (date_string) {
        return Math.round(((new Date(date_string)).getTime() - (new Date()).getTime()) / 1000);
      };

      portalHelperService.days_since = function(date) {
        return -portalHelperService.getDaysRemaining(date);
      };

      portalHelperService.today_or_yesterday = function(dateString) {
        if (dateString) {
          var dt = new Date(dateString);
          var today = new Date();

          var yesterday = new Date(new Date().setDate(today.getDate() - 1));
          if (dt.getDate() === today.getDate() && dt.getMonth() === today.getMonth() && dt.getYear() === today.getYear()) {
            return 'Today';
          }

          if (dt.getDate() === yesterday.getDate() && dt.getMonth() === yesterday.getMonth() && dt.getYear() === yesterday.getYear()) {
            return 'Yesterday';
          }
        }

        return false;
      };

      portalHelperService.get_days_month_years = function(old_date) {
        var days_since = portalHelperService.days_since(old_date);
        var months_since = portalHelperService.numberOfMonths(old_date);
        var years_since = parseInt(months_since / 12);

        if (days_since <= 31) {
          return days_since + ' days ago';
        } else if (months_since < 13) {
          return months_since + (months_since > 1 ? ' months' : ' month') + ' ago';
        } else {
          return years_since + (years_since > 1 ? ' years' : ' year') + ' ago';
        }
      };

      portalHelperService.numberOfMonths = function(old_date) {
        var today = new Date();
        old_date = new Date(old_date);
        var years_since = today.getYear() - old_date.getYear();
        var months_since = today.getMonth() - old_date.getMonth();
        months_since = months_since + years_since * 12;
        return months_since;
      };

      portalHelperService.getTimeRemaining = function (date_string, zero_string) {
        if (zero_string === undefined) {
          zero_string = '0';
        }

        if (new Date(date_string) < new Date()) {
          return zero_string;
        }

        let diffTime = moment(new Date(date_string)).diff(moment());
        let duration = moment.duration(diffTime);
        let days = duration.days(),
          hours = duration.hours(),
          minutes = duration.minutes(),
          seconds = duration.seconds();

        let timeRemStr = '';
        if (days >= 1) {
          timeRemStr =  (days === 1 ? '1 day' : days + ' days') ;
        }

        if (hours >= 1) {
          let hoursStr = (hours === 1 ? '1 hour' : hours + ' hours');
          if (timeRemStr) {
            return timeRemStr + ' and ' + hoursStr;
          }

          timeRemStr = hoursStr;
        }

        if (minutes >= 1) {
          let minutesStr = (minutes === 1 ? '1 minute' : minutes + ' minutes');
          if (timeRemStr) {
            return timeRemStr + ' and ' + minutesStr;
          }

          timeRemStr = minutesStr;
        }

        if (seconds >= 1) {
          let secondsStr = (seconds === 1 ? '1 second' : seconds + ' seconds');
          if (timeRemStr) {
            if (days >= 1) {
              return timeRemStr;
            }
            return timeRemStr + ' and ' + secondsStr;
          }

          return secondsStr;
        }

        return timeRemStr || '0';
      };

      portalHelperService.getPayerTimeRemaining = function (date_string, overdue_string, expired_string) {
        var seconds = Math.round(((new Date(date_string)).getTime() - (new Date()).getTime()) / 1000);
        if (seconds < -24 * 3600) {
          return expired_string;
        }

        return portalHelperService.getTimeRemaining(date_string, overdue_string);
      };

      portalHelperService.getPayerTimeRemainingClassName = function (date_string) {
        var seconds = Math.round(((new Date(date_string)).getTime() - (new Date()).getTime()) / 1000);
        const SECONDS_IN_DAY = 24 * 60 * 60;
        if (seconds < SECONDS_IN_DAY) {
          if (seconds > (2 * 3600)) {
            return 'text-warning fw-bold';
          }
          return 'text-danger fw-bold';
        }
        return 'text-success fw-bold';
      };

      portalHelperService.getPayerTimeRemainingIconClassName = function (date_string) {
        return portalHelperService.getPayerTimeRemainingClassName(date_string).includes('text-success') ?
          'fa fa-lg fa-clock-o' :
          'fa fa-exclamation-triangle';
      };

      portalHelperService.escapeDocumentNames = function (documents_list) {
        if (!documents_list){
          return;
        }

        for (var i = 0; i < documents_list.length; ++i) {
          documents_list[i].name_esc = encodeURIComponent(documents_list[i].name).replace(/%2F/g,
            '%252F');
        }
      };

      portalHelperService.getFlagUrl = function (flag_name) {
        return '../Images/Flags/' + flag_name;
      };

      portalHelperService.addApostrophe = function (text) {
        if (text == null || text == '') {
          return '';
        }

        if (text[text.length - 1] === 's') {
          return text + '\'';
        }

        return text + '\'s';
      };

      portalHelperService.showPopupError = function (title, text, ok_callback, scope) {
        return $ngConfirm({
          title: title,
          scope: scope,
          content: text,
          type: 'red',
          buttons: {
            OK: {
              btnClass: 'mat ui critical button',
              action: ok_callback
            }
          }
        });
      };

      portalHelperService.showPopupWarning = function (title, text, ok_callback, scope) {
        return $ngConfirm({
          title: title,
          scope: scope,
          content: text,
          type: 'orange',
          buttons: {
            OK: {
              btnClass: 'mat ui primary button',
              action: ok_callback
            }
          }
        });
      };

      portalHelperService.showPopupYesNo = function (
        title, text, yes_callback, no_callback, yes_text, no_text, destroy_callback, color) {

        yes_callback = portalHelperService.fix_undefined(yes_callback);
        no_callback = portalHelperService.fix_undefined(no_callback);

        yes_text = (yes_text) ? yes_text : 'Yes';
        no_text = (no_text) ? no_text : 'No';

        return $ngConfirm({
          title: title,
          content: text,
          type: color || 'orange',
          closeIcon: false,
          escapeKey: false,
          backgroundDismiss: false,
          onDestroy: destroy_callback,
          buttons: {
            Yes: {
              text: yes_text,
              btnClass: 'mat ui primary button',
              action: function (scope, button) {
                if (yes_callback) {
                  return yes_callback(scope, button);
                }
              }
            },
            No: {
              btnClass: 'mat ui critical button',
              text: no_text,
              action: function (scope, button) {
                if (no_callback) {
                  return no_callback(scope, button);
                }
              }
            }
          }
        });
      };

      portalHelperService.showPopupOK = function (title, text, callback) {
        $.confirm({
          title: title,
          content: text,
          type: 'green',
          typeAnimated: true,
          buttons: {
            OK: {
              btnClass: 'mat ui primary button',
              action: function () {
                if (callback && typeof (callback) === 'function') {
                  callback();
                }
              }
            }
          }
        });
      };

      portalHelperService.showConfirmOkWithDestroy = function (title, text, fn_ok) {
        var cur_scope = $rootScope.$new(true);
        cur_scope.clicked = false;

        $ngConfirm({
          title: title,
          content: text,
          type: 'green',
          onDestroy: function () {
            if (cur_scope.clicked && fn_ok) {
              fn_ok();
            }
          },
          buttons: {
            OK: {
              btnClass: 'mat ui primary button',
              action: function () {
                cur_scope.clicked = true;
              }
            }
          }
        });
      };

      portalHelperService.showToast = function (toastType, text, title, make_sticky) {
        var allowedTypes = ['info', 'warning', 'success', 'error'];
        if (!_.contains(allowedTypes, toastType)) {
          toastType = 'info';
        }

        var toastrOptions = make_sticky && (make_sticky === true)
          ? { 'timeOut': 0, 'extendedTimeOut': 0 }
          : {};

        toastr[toastType](text, title, toastrOptions);
      };

      portalHelperService.showPopup = function (title, text, close_callback, color) {
        close_callback = portalHelperService.fix_undefined(close_callback);

        $.confirm({
          title: title,
          content: text,
          type: (color) ? color : 'green',
          typeAnimated: true,
          buttons: {
            Ok: {
              text: 'Ok',
              btnClass: 'mat ui primary button',
              action: function () {
                if (close_callback) {
                  close_callback();
                }
              }
            }
          }
        });
      };

      portalHelperService.initPostcodeInput = function ($scope) {
        $scope.postcode_regex = '';
        $scope.postcode_placeholder = '';

        if ($scope.payer.country_of_residence == portalHelperService.countryCode.UK) {
          $scope.postcode_regex = /^[A-Z]{1,2}[0-9][A-Z0-9]?\s+[0-9][A-Z]{2}$/i;
          $scope.postcode_placeholder = 'e.g. CB3 9ET';
        }
        else if ($scope.payer.country_of_residence == portalHelperService.countryCode.US) {
          $scope.postcode_regex = /^[0-9]{5}$/i;
          $scope.postcode_placeholder = 'e.g. 82190';
        }
      };

      portalHelperService.clearStateIfNeeded = function ($scope) {
        var state = $scope.payer.address_line4;
        var states_list = '';

        if ($scope.payer.country_of_residence == portalHelperService.countryCode.Canada) {
          states_list = $scope.payer_lists['states']['Canada'];
        }
        else if ($scope.payer.country_of_residence == portalHelperService.countryCode.US) {
          states_list = $scope.payer_lists['states']['United States'];
        }

        if (states_list && states_list.indexOf(state) === -1) {
          $scope.payer.address_line4 = '';
        }
      };

      portalHelperService.overWritePayerOptionSelectedValues = function (oldResponses, newResponses) {
        for (var key in oldResponses) {
          if (Object.prototype.hasOwnProperty.call(newResponses, key)) {
            newResponses[key].payer_option_selected = oldResponses[key].payer_option_selected;
          }
        }
      };

      portalHelperService.populatePayerOptionSelected = function (responses) {
        for (var i = 0; i < responses.length; i++) {
          responses[i].payer_option_selected = !stringHelper.isNullOrEmpty(responses[i].payer_option);
        }
      };

      portalHelperService.populateClarificationRequestEditable = function (responses) {
        for (var i=0; i<responses.length; i++) {
          responses[i].is_clar_request_editable = false;
          if (responses[i].clarification_response == null) {
            responses[i].is_clar_request_editable = responses[i].clarification_request !== null;
          } else if (responses[i].clarification_response.rejection_history == null || responses[i].clarification_response.rejection_history.length === 0) {
            responses[i].is_clar_request_editable = false;
          } else if (responses[i].clarification_response.is_rejected == null) {
            responses[i].is_clar_request_editable = false;
          } else {
            responses[i].is_clar_request_editable = responses[i].clarification_response.is_rejected;
          }
        }
      };

      portalHelperService.handleFloatValues = function (responses) {
        for (var i = 0; i < responses.length; i++) {
          if (!responses[i].van_westendorp_responses) {
          } else {
            responses[i].van_westendorp_responses.acceptable =
              parseFloat(responses[i].van_westendorp_responses.acceptable);
            responses[i].van_westendorp_responses.expensive =
              parseFloat(responses[i].van_westendorp_responses.expensive);
            responses[i].van_westendorp_responses.too_expensive =
              parseFloat(responses[i].van_westendorp_responses.too_expensive);
            responses[i].van_westendorp_responses.prohibitive =
              parseFloat(responses[i].van_westendorp_responses.prohibitive);
          }
        }

        return responses;
      };

      portalHelperService.showFileSelectorPopup = function (cur_scope, new_document_owner_id, list_of_documents,
        selected_document_ids, callback, callback_on_doc_uploaded) {
        cur_scope.document_uploader = {
          document: {
            owner_id: new_document_owner_id
          },
          new_document_added: false,
          scope: cur_scope,
          selected_documents: []
        };

        cur_scope.document_uploader.upload_method = function (document, surveyId) {
          if (cur_scope.document_uploading != undefined && cur_scope.document_uploading === true) {
            return;
          }

          if (!document.files || document.files.length === 0) {
            portalHelperService.showPopup('Documents not selected',
              'Please select documents to upload.', null, 'red');
            return false;
          }

          for (let i = 0; i < document.files.length; i++) {
            let file = document.files[i];
            if (!(new RegExp('.PDF$', 'i')).test(file.name)) {
              portalHelperService.showPopup('Only PDF files allowed',
                'Please select a PDF file to continue.', null, 'red');
              return false;
            }
          }

          cur_scope.document_uploading = true;

          var url = apiHelper.getApiUrl() + '/api/authoring/document.json';
          var http_method = HttpAuth.post;
          var docObj = this;
          const data = new FormData();
          for (let i = 0; i < document.files.length; i++) {
            data.append('files', document.files[i]);
          }
          data.append('survey', surveyId);
          data.append('owner_id ', document.owner_id);

          var headers = {
            headers: { 'Content-Type': undefined }
          };

          http_method(url, data, headers).then(function (result) {
            var newDocs = result.data.map(function(doc) {
              return {
                'id': doc.id,
                'name': doc.name,
                'survey_id': doc.survey,
                'added_datetime': doc.added_datetime
              };
            });

            $timeout(function () {
              var table = $('#documents-list-table').DataTable();
              table.rows.add(newDocs).draw();
            }, 0);

            docObj.new_document_added = true;

            docObj.documents = [];

            docObj.scope.documentUploadForm.$setPristine();

            $('#file-picker').val('');

            $timeout(function(){
              $('#documents-list-table tbody input').on('click', function(event){
                event.stopPropagation();
              });
            });

            if (callback_on_doc_uploaded instanceof Function) {
              callback_on_doc_uploaded(
                function(result){
                  clientListService.list.document = clientListService.list.document.filter(
                    function(document){
                      return document.owner_id == cur_scope.client_user_id;
                    }
                  );
                }
              );
            }
          }, function (result) {
            portalHelperService.showErrorCommon(result);
          }).finally(function () {
            cur_scope.document_uploading = false;
          });
        };

        var documentList = list_of_documents;
        if (selected_document_ids != null && selected_document_ids.length > 0) {
          documentList = _.filter(list_of_documents, function (doc) {
            return selected_document_ids.indexOf(parseInt(doc.id)) < 0;
          });
        }

        $ngConfirm({
          title: 'Select Documents to attach to the Survey',
          contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/client_document_manager.3f8ad479.html'),
          type: 'blue',
          closeIcon: true,
          columnClass: 'large',
          escapeKey: 'cancel',
          scope: cur_scope,
          onReady: function (scope) {
            scope.document_uploader.selected_documents = [];

            $(document).ready(function () {
              $('#file-picker').change(function (event) {
                scope.$apply(function () {
                  scope.document_uploader.document.files = event.target.files;
                });
              });
            });

            $timeout(function () {
              var documentsListTable = $('#documents-list-table').DataTable({
                bDestroy: true,
                autoWidth: true,
                ordering: true,
                data: documentList,
                scrollY: '200px',
                deferRender: true,
                scrollCollapse: true,
                paging: false,
                order: [[4, 'desc']],
                columns: [
                  {
                    data: 'id',
                    visible: false,
                    type: 'num'
                  },
                  {
                    data: 'id',
                    width: '35px',
                    orderable: false,
                    render: function (data, type, row, meta) {
                      return [
                        '<div class=\'center aligned\'>',
                        '<input type=\'checkbox\' data-id=\'' + data + '\' id=\'doc-id-' + data + '\' />',
                        '</div>'
                      ].join('\n');
                    }
                  },
                  {
                    data: 'name',
                    title: 'Document',
                    render: function (data, type, row, meta) {
                      return [
                        '<label>',
                        data,
                        '</label>'
                      ].join('\n');
                    }
                  },
                  {
                    data: 'survey_id',
                    title: 'Survey ID',
                    render: function (data, type, row) {
                      return 'MAT_' + data;
                    }
                  },
                  {
                    data: 'added_datetime',
                    title: 'Uploaded',
                    type: 'date',
                    defaultContent: '',
                    render: function (data, type, row, meta) {
                      if (type === 'display' || type === 'filter') {
                        return $filter('date')(data, CONSTANTS.dateTimeFormat.MEDIUM_ONLY_DATE);
                      }

                      return data;
                    }
                  }
                ]
              });

              $(document).ready(function() {
                $('#documents-list-table tbody').on('click', 'tr', function (event) {
                  $(this).find('input').click();
                });

                $('#documents-list-table tbody input').on('click', function(event){
                  event.stopPropagation();
                });

              } );

            }, 0);
          },
          buttons: {
            cancel: {
              text: 'Cancel',
              btnClass: 'mat ui button',
              action: function (scope, button) {
              }
            },
            select: {
              text: 'Add Selected Documents',
              btnClass: 'mat ui primary button',
              action: function (scope, button) {
                $('#documents-list-table input[type=checkbox]:checked').each(function () {
                  scope.document_uploader.selected_documents.push($(this).data('id'));
                });

                if (scope.document_uploader.selected_documents.length === 0) {
                  portalHelperService.showPopup('No documents selected',
                    'Please select one or more documents to attach.', null, 'red');
                  return false;
                }

                if (callback !== null && typeof (callback) == 'function') {
                  callback({
                    selected_documents: scope.document_uploader.selected_documents,
                    new_document_added: scope.document_uploader.new_document_added
                  });
                }
              }
            }
          }
        });
      };

      portalHelperService.initQuestionBankTable = function (scope, questionBankList) {

        var columns = [
          {
            data: 'id',
            visible: false,
            type: 'num'
          },
          {
            data: 'text',
            orderable: false
          },
          {
            data: 'id',
            orderable: false,
            render: function (data, type, row, meta) {
              return [
                '<button data-ng-click=\'submit_question(' + data + ')\' class=\'mat ui primary button\'>Include</button>'
              ].join('\n');
            }
          }
        ];

        $('#questions-list-table').DataTable({
          bDestroy: true,
          autoWidth: false,
          ordering: true,
          order: [[0, 'desc']],
          data: questionBankList,
          scrollCollapse: true,
          paging: false,
          columns: columns,
          createdRow: function (row, data, index) {
            $compile(angular.element(row).contents())(scope);
          },
          responsive: {
            details: {
              display: $.fn.dataTable.Responsive.display.childRowImmediate,
              type: ''
            }
          },
          initComplete: function (settings, json) {
            setTimeout(function () {
              $('#table-copy-questions').DataTable().columns.adjust().responsive.recalc();
              preventDataTableMenuClipping();
            }, 500);
          }
        }).draw();
      };

      portalHelperService.showQuestionBankPopup = function (cur_scope, questionBankList, callback) {
        var questionBankPopup = null;

        cur_scope.submit_question = function (questionId) {
          var question = _.findWhere(questionBankList, { id: questionId });
          if (question !== undefined) {
            if (callback != null && typeof (callback) === 'function') {
              callback(question.text);

              if (questionBankPopup) {
                questionBankPopup.close();
                questionBankPopup = null;
              }
            }
          }
        };

        questionBankPopup = $ngConfirm({
          title: '<strong>Select a Question to use</strong>',
          contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/question_bank_popup.789e040e.html'),
          type: 'blue',
          columnClass: 'col-md-8',
          closeIcon: true,
          escapeKey: 'cancel',
          scope: cur_scope,
          onReady: function (scope) {
            portalHelperService.initQuestionBankTable(scope, questionBankList);
          },
          buttons: {
            cancel: {
              text: 'Cancel',
              btnClass: 'mat ui button',
              action: function (scope, button) {
              }
            }
          }
        });
      };

      portalHelperService.retrieveCurrentUserAndEnterPortal = function(){
        portalHelperService.retrieveMeShared(function (data) {
          if (portalHelperService.checkTfaRequired(data)) {
            $location.path('/TfaRequired');
            return;
          }

          authHelper.setMeSharedOrig(data);
          INTERCOM.initForUser(data, authHelper.getUserType());
          authHelper.redirectLoggedIn($routeParams.returnUrl);
        }, false, true);
      };


      portalHelperService.handleBlockedPopup = function(win, surveyId){
        if (!win || win.closed || typeof win.closed=='undefined') {
          var popupBlockedMessage = $ngConfirm({
            title: ' ',
            closeIcon: false,
            columnClass: 'large',
            type: 'red',
            contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/blocked_popup.9b6cf474.html'),
            onScopeReady: function(scope) {
              var blockCountKey = 'count_blocked_survey';

              if (localStorage.getItem(blockCountKey) == null) {
                localStorage.setItem(blockCountKey, 0);
              } else {
                scope.blockedCount = localStorage.getItem(blockCountKey) == null
                  ? 0
                  : parseInt(localStorage.getItem(blockCountKey));

                scope.blockedCount += 1;
                localStorage.setItem(blockCountKey, scope.blockedCount);
                scope.surveyId = surveyId;
              }

              scope.openSurveyDirectly = function(){
                $timeout(
                  function(){
                    popupBlockedMessage.close();
                    window.location.reload();
                  }, 200
                );
                $location.path('Payers/SurveyDescription/' + surveyId);
              };
            },
            buttons: {
              OK: {
                btnClass: 'mat ui primary button',
                action: function () {
                  popupBlockedMessage.close();
                  location.reload();
                }
              }
            }
          });

          return true;
        }
      };

      portalHelperService.showLoaderWithMessage = function (title, message) {
        return $ngConfirm({
          title: title,
          contentUrl: portalHelperService.getUrlRev(
            'html_templates/dialogs/message_with_loader.bc47b42f.html'),
          type: 'orange',
          onScopeReady: function (scope) {
            scope.message = message;
          },
          buttons: {
            OK: {
              btnClass: 'mat ui primary button'
            }
          }
        });
      };

      portalHelperService.enableWeekSelectionOnCalendar = function () {
        $('tbody').addClass('week-selection');
      };

      portalHelperService.handleDataTableRowClick = function (tableId, clickHandler) {
        $('#' + tableId).addClass('highlight-rows');

        var dt = $('#' + tableId).DataTable();

        $('#' + tableId + ' tbody').on('click', 'tr', function () {
          if (clickHandler !== null && typeof(clickHandler) === 'function') {
            clickHandler(dt.row(this).data());
          }
        });
      };

      portalHelperService.client_survey_create = function (owner_id) {

        var surveyPopup = $ngConfirm({
          title: 'Create Survey',
          closeIcon: true,
          columnClass: 'large',
          contentUrl: portalHelperService.getUrlRev('html_templates/dialogs/client_create_survey.0633463d.html'),
          onScopeReady: function (scope) {

            scope.survey_to_be_created = {
              new: 'true'
            };

            HttpAuth.get(apiHelper.getApiUrl() + '/api/authoring/project.json').then(
              function(result){
                scope.projects = result.data.filter(
                  function (project) {
                    return project.project_code.length > 0;
                  }
                );
              }
            );

            scope.create_project = function (survey) {
              var url = apiHelper.getApiUrl() + '/api/authoring/project.json';
              var data = {
                owner_id: owner_id,
                topic: survey.topic
              };
              return HttpAuth.post(url, data);
            };

            scope.create_survey = function (survey) {

              if (survey.new == 'true') {
                scope.create_project(survey).then(
                  function (result) {
                    survey.project_id = result.data.id;
                    scope.saveSurvey(survey);
                  }
                );
              }
              else {
                scope.saveSurvey(survey);
              }

            };

            scope.saveSurvey = function (survey) {

              if (!survey.name) {
                portalHelperService.showToast(CONSTANTS.typeOfToasts.ERROR, 'Survey Title can\'t be empty');
                return;
              }
              else {
                if ( (survey.new === 'false') && !survey.project_id) {
                  portalHelperService.showToast(
                    CONSTANTS.typeOfToasts.ERROR,
                    'Select a project from existing project list or mark as a new project'
                  );
                  return;
                }
              }

              var url = apiHelper.getApiUrl() + '/api/authoring/survey.json';
              var data = angular.copy(survey);
              data.owner_id = owner_id;
              data.start_date = null;

              HttpAuth.post(url, data).then(
                function (result) {
                  portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, 'Survey created successfully');
                  surveyPopup.close();
                  var title = 'Success';
                  var message = '<b>MAT_'+ result.data.id + '</b> was created Successfully.<br/>'+
                    'Would you like to open the Project Brief screen for this survey?';
                  var yesCallback = function () {
                    $timeout(function () {
                      $location.path('Client/CreateProject/' + result.data.id);
                    });
                  };

                  portalHelperService.showPopupYesNo(
                    title,
                    message,
                    yesCallback,
                    null,
                    null,
                    null,
                    null,
                    'green'
                  );
                },
                function (result) {
                  portalHelperService.showErrorCommon(result);
                }
              );
            };

            scope.cancel = function () {
              surveyPopup.close();
            };

          }

        });
      };


      portalHelperService.process_country_counts = function(data, country_dict) {
        var tempArray = [];
        var result = '';

        for (var key in data) {
          tempArray.push({ 'k': key, 'v': data[key] });
        }

        var grouped = _.groupBy(tempArray, 'v');

        for (key in grouped) {
          var countries = [];
          grouped[key].forEach(function (elem, index) {
            countries.push(country_dict[elem.k].name);
          });

          result += key + ' (' + countries.join(', ') + '); ';
        }

        return result;
      };

      portalHelperService.get_milestone_index = function (milestone) {
        var milestoneOrdering = [
          CONSTANTS.surveyStatuses.SCOPING,
          CONSTANTS.surveyStatuses.QUESTION_DEVELOPMENT,
          CONSTANTS.surveyStatuses.COMPLIANCE,
          CONSTANTS.surveyStatuses.FIELDING_AND_ANALYSIS,
          CONSTANTS.surveyStatuses.FOLLOW_UP,
          CONSTANTS.surveyStatuses.DELIVERED
        ];

        return milestoneOrdering.indexOf(milestone);
      };

      portalHelperService.AppendColumnFiltersOnDatatable = function(tableRef, tableId){
        var tableSelector = '#' + tableId;
        var searchRow = $('<tr class="filter-row">');
        var numOfColumns = tableRef.columns(':visible').nodes().length;
        for (var i=0; i<numOfColumns; i++) {
          searchRow.append('<th>');
        }

        $(searchRow).appendTo(tableSelector + ' thead');
        $(tableSelector + ' thead tr:eq(1) th').each(function (i) {
          var parentHeaderCell = $(tableSelector + ' thead tr:eq(0) th')[i];
          if (!$(parentHeaderCell).hasClass('skipCol')) {
            var title = $(parentHeaderCell).text();
            $(this).html( '<input type="text" placeholder="Search '+title+'" />' );

            $( 'input', this ).on( 'keyup change', function () {
              if ( tableRef.column(i).search() !== this.value ) {
                tableRef
                  .column(i)
                  .search( this.value )
                  .draw();
              }
            } );
          }
        } );
      };

      portalHelperService.render_clarification_statuses = function (row) {

        if (!row.clarification_statuses) {
          return '';
        }
        var output = '';
        for (var i = 0; i < row.clarification_statuses.length; i++) {
          var cur_status = row.clarification_statuses[i];

          if (cur_status.status === 'received') {
            output += (
              '<span class=\'badge bg-info\' title=\'' + cur_status.status + '\'>' +
            '<i class=\'fa fa-fw fa-arrow-circle-down\'></i> Q' +
            (cur_status.question_label || cur_status.question_number) +
            '</span> '
            );
          } else if (cur_status.status === 'approved') {
            output += (
              '<span class=\'badge bg-success\' title=\'' + cur_status.status + '\'>' +
            '<i class=\'fa fa-fw fa-check\'></i> Q' +
            (cur_status.question_label || cur_status.question_number) +
            '</span> '
            );
          } else if (cur_status.status === 'not_sent') {
            output += (
              '<span class=\'badge bg-secondary\' title=\'Clarification raised but not sent\'>' +
            '<i class=\'fa fa-fw fa-arrow-circle-up\'></i> Q' +
            (cur_status.question_label || cur_status.question_number) +
            '</span> '
            );
          } else if (cur_status.status === 'pending') {
            output += (
              '<span class=\'badge bg-warning\' title=\'Pending response from Payer\'>' +
            '<i class=\'fa fa-fw fa-arrow-circle-up\'></i> Q' +
            (cur_status.question_label || cur_status.question_number) +
            '</span> '
            );
          }
        }
        return output;
      };

      portalHelperService.validateEmail = function (email_address){
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email_address))
        {
          return (true);
        }
        return (false);
      };

      portalHelperService.fetch_names_from_array = function (values, ids, separator) {
        if (values == null || ids == null || values.length === 0 || ids.length === 0) {
          return null;
        }

        separator = separator == undefined ? ', ' : separator;

        return _.pluck(
          _.filter(values,
            function (value) {
              return ids.indexOf(value.id) > -1;
            }), 'name').join(separator);
      };

      portalHelperService.calculate_first_survey_milestone_date = function (survey) {
        if (survey.question_development === true && survey.question_development_minimum_date != null) {
          return survey.question_development_minimum_date;
        }

        return survey.start_date;
      };

      portalHelperService.is_browser_ie = function () {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        return false;
      };

      portalHelperService.tor_classic_has_long_dropdown_options = function(options) {
        var DROPDOWN_TEXT_LENGTH_MAX = 10;

        if (options) {
          for (var i=0; i<options.length; i++) {
            if (options[i].option_name.length > DROPDOWN_TEXT_LENGTH_MAX) {
              return true;
            }
          }
        }

        return false;
      };

      portalHelperService.fetch_type_of_page = function() {
        var absPath = $location.absUrl().toLowerCase();

        if (absPath.indexOf('/admin/#/') >= 0) {
          return CONSTANTS.typeOfPage.ADMIN;
        }

        if (absPath.indexOf('/client/#/') >= 0) {
          return CONSTANTS.typeOfPage.CLIENT;
        }

        if (absPath.indexOf('/payer/#/') >= 0) {
          return CONSTANTS.typeOfPage.PAYER;
        }

        return null;
      };

      portalHelperService.set_auto_size_text_areas = function (classname, waitDuration) {
        var selector = 'textarea.' + classname;

        var duration = waitDuration ? waitDuration : 100;
        $timeout(function () {
          autosize(angular.element(selector));
        }, duration);
      };

      portalHelperService.initializeTelephoneInput = function (idSelector) {
        var input = document.querySelector(idSelector);
        var utilScriptUrl = '../../lib/thirdparty/intl-tel-input-17.0.0/js/utils.js';
        var iti = window.intlTelInput(input, {
          separateDialCode: true,
          utilsScript: utilScriptUrl
        });
        window.intlTelInputGlobals.loadUtils(utilScriptUrl);
        $(idSelector).keypress(function (e) {
          var keyCode = e.which;
          if (!((keyCode >= 48 && keyCode <= 57))
            && [8, 32, 40, 41, 43, 45].indexOf(keyCode) === -1) {
            e.preventDefault();
          }
        });

        var validationErrors = {
          '0' : 'Invalid number',
          '1' : 'Invalid country code',
          '2' : 'Too short',
          '3' : 'Too long',
          '4' : 'Invalid number',
          '5' : 'Invalid length',
          '-99' : 'Invalid number'
        };

        var telInputHandle = {
          'get_number': function () {
            return iti.getNumber();
          },
          'set_number': function (phoneNumber) {
            if (!phoneNumber) {
              return null;
            }
            return iti.setNumber(phoneNumber);
          },
          'is_valid_number': function () {
            return iti.isValidNumber();
          },
          'get_validation_error': function () {
            var error = iti.getValidationError();
            return validationErrors[error] + '. Enter a valid international number';
          }
        };

        return telInputHandle;

      };

      portalHelperService.shuffleArrayWithSeed = function (array, seed) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        seed = seed || 1;
        var random = function() {
          var x = Math.sin(seed++) * 10000;
          return x - Math.floor(x);
        };

        while (0 !== currentIndex) {
          randomIndex = Math.floor(random() * currentIndex);
          currentIndex -= 1;
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
      };

      portalHelperService.bankersRounding = function (num, decimalPlaces) {
        var d = decimalPlaces || 0;
        var m = Math.pow(10, d);
        var n = +(d ? num * m : num).toFixed(8); 
        var i = Math.floor(n), f = n - i;
        var e = 1e-8; 
        var r = (f > 0.5 - e && f < 0.5 + e) ?
          ((i % 2 === 0) ? i : i + 1) : Math.round(n);
        return d ? r / m : r;
      };

      portalHelperService.is_test_survey = function () {
        return $location.path().indexOf('TestSurvey/') !== -1;
      };

      portalHelperService.isValidJsonString = function (str) {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      };

      portalHelperService.getMyDetails = function () {
        var myDetailsStr = sessionStorage.myDetails;
        if (myDetailsStr) {
          var myDetails = JSON.parse(myDetailsStr);
          return myDetails;
        }
        else return null;
      };

      portalHelperService.autosizeTextAreas = function () {
        $timeout(function () {
          autosize(angular.element('textarea.expandable'));
        });
      };

      portalHelperService.humanize_date_diff = function (momentDate1, momentDate2) {
        return moment.duration(momentDate1.diff(momentDate2)).humanize();
      };
      portalHelperService.calculate_date_diff = function (momentDate1, momentDate2) {
        const years = momentDate1.diff(momentDate2, 'years', true).toFixed(1);
        const months = momentDate1.diff(momentDate2, 'months', true).toFixed(1);
        const days = momentDate1.diff(momentDate2, 'days', true).toFixed(1);
        const hours = momentDate1.diff(momentDate2, 'hours', true).toFixed(1);
        const minutes = momentDate1.diff(momentDate2, 'minutes', true).toFixed(1);
        const seconds = momentDate1.diff(momentDate2, 'seconds', true).toFixed(1);

        if (years >= 1) {
          return years + ' years';
        }
        if (years < 1 && months >= 1) {
          return months + ' months';
        }
        if (months < 1 && days >= 1) {
          return days + ' days';
        }
        if (days < 1 && hours >= 1) {
          return hours + ' hours';
        }
        if (hours < 1 && minutes >= 1) {
          return minutes + ' minutes';
        }

        return seconds + ' seconds';
      };

      portalHelperService.has_json_format = function (str) {
        if (typeof str !== 'string') return false;
        try {
          const result = JSON.parse(str);
          const type = Object.prototype.toString.call(result);
          return type === '[object Object]'
            || type === '[object Array]';
        } catch (err) {
          return false;
        }
      };

      portalHelperService.removeHTMLTags = function (str) {
        if (typeof str !== 'string') return str;
        str = str.replace(/<[^>]*>/g,'');
        str = str.replace(/&nbsp;|&emsp;/g,'');
        return str;
      };

      portalHelperService.get_utc_time_in_24HH_mm_format = function (dateStr) {
        return moment.utc(dateStr).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_24H_TIME_NO_SEC);
      };

      portalHelperService.popup_window_open = function (url, target, options) {
        var promiseObj = new Promise(function (resolve, reject) {
          window.setTimeout(function() {
            var handle = window.open(url, target || '_blank', options || '');
            if (!handle) { 
              portalHelperService.showPopupError('Your browser prevented a pop up from opening.',
                'Please allow your browser to show pop ups from this website to continue.');
            }
            resolve(handle);
          }, 200);
        });
        return promiseObj;
      };

      portalHelperService.routeResolve = function () {
        return authHelper.getLoginDetils.then(
          function (result) {
            var myDetails = result.data;
            if (myDetails.is_contract_sign_required && myDetails.docusign_enabled) {
              sendPayerToContractPage();
            }
            else if (myDetails.show_terms) {
              sendPayerToTermsOfUsePage();
            }

            return myDetails;
          },
          function (error) {
            if ('abort' != error.xhrStatus) {
              portalHelperService.showErrorCommon(error);
            }
          }
        );
      };

      portalHelperService.get_list_items_from_paste_event = function (pasteEvent) {
        let event = pasteEvent.originalEvent || pasteEvent;
        let clipboardData = event.clipboardData || window.clipboardData;
        let pastedText = clipboardData.getData('text/html');
        if (!pastedText) {
          return [];
        }

        pastedText = pastedText.replace(/<!--[\s\S]*?-->/g, '');

        pastedText = pastedText.replace(/<!\[if !supportLists\]>[\s\S]*?<!\[endif\]>/g, '');
        let doc = document.implementation.createHTMLDocument();
        doc.body.innerHTML = pastedText;

        let listItems = doc.getElementsByTagName('li');
        if (!listItems.length) {
          listItems = doc.getElementsByTagName('tr');
        }

        if (!listItems.length) {
          listItems = doc.getElementsByTagName('p');
        }

        return _.map(listItems, function (item) {
          return {
            text: (item.innerText.trim()).replace(/\n/g,'')
          };
        });
      };

      portalHelperService.addChangeHistoryLabel = function(payer_relevance_rating_choices, relevanceRatingObj) {
        if (!relevanceRatingObj.rating_history_json || (relevanceRatingObj.rating_history_json.length === 0 && !relevanceRatingObj.updated_datetime)) {
          return relevanceRatingObj;
        }
        relevanceRatingObj.rating_history_log = [...relevanceRatingObj.rating_history_json];
        let ratingHistory = relevanceRatingObj.rating_history_log;
        let currentRating, currentRatingLabel, previousRating, previousRatingLabel, lastRating, lastRatingLabel;

        _.forEach(relevanceRatingObj.rating_history_log, function(historyObj, index) {
          if (index === 0) {
            const initialRatingLabel = `${payer_relevance_rating_choices[ratingHistory[0].rating].label} (${ratingHistory[0].rating})`;
            relevanceRatingObj.rating_history_log[0].change_history_log_label = `Initial Rating: ${initialRatingLabel}`;
          } else {
            previousRating = ratingHistory[index - 1].rating ? ratingHistory[index - 1].rating : 0;
            currentRating = ratingHistory[index].rating ? ratingHistory[index].rating : 0;
            previousRatingLabel = `${payer_relevance_rating_choices[previousRating].label} (${previousRating})`;
            currentRatingLabel = `${payer_relevance_rating_choices[currentRating].label} (${currentRating})`;
            relevanceRatingObj.rating_history_log[index].change_history_log_label = `${previousRatingLabel} to ${currentRatingLabel}`;
          }
        });

        currentRating = relevanceRatingObj.rating ? relevanceRatingObj.rating : 0;
        currentRatingLabel = `${payer_relevance_rating_choices[currentRating].label} (${currentRating})`;

        if (ratingHistory.length > 0) {
          lastRating = ratingHistory[ratingHistory.length - 1].rating ? ratingHistory[ratingHistory.length - 1].rating : 0;
          lastRatingLabel = `${payer_relevance_rating_choices[lastRating].label} (${lastRating})`;

          relevanceRatingObj.rating_history_log.push({
            rating: relevanceRatingObj.rating,
            updated_datetime: relevanceRatingObj.updated_datetime,
            change_history_log_label: `${lastRatingLabel} to ${currentRatingLabel}`
          });
        } else if (relevanceRatingObj.updated_datetime) {
          relevanceRatingObj.rating_history_log.push({
            rating: relevanceRatingObj.rating,
            updated_datetime: relevanceRatingObj.updated_datetime,
            change_history_log_label: `Initial Rating: ${currentRatingLabel}`
          });
        }
        return relevanceRatingObj;
      };

      portalHelperService.getSortedTimezones = function (timezones) {
        if (timezones) {
          let sortedTimezones = timezones.sort(function (a, b) {
            let aHourPart = parseInt(a.utcoffset.split(':')[0]) * 60;
            let aMinutePart = parseInt(a.utcoffset.split(':')[1]);
            let bHourPart = parseInt(b.utcoffset.split(':')[0]) * 60;
            let bMinutePart = parseInt(b.utcoffset.split(':')[1]);
            let aOffsetValue = aHourPart + (aHourPart > 0 ? aMinutePart : -aMinutePart);
            let bOffsetValue = bHourPart + (bHourPart > 0 ? bMinutePart : -bMinutePart);
            return aOffsetValue - bOffsetValue;
          });
          return sortedTimezones;
        }
      };


      portalHelperService.getLastAgreementInfo = function (payer) {
        if (!payer || !payer.contract_status_label) {
          return '';
        }
        var contract_status_label = payer.contract_status_label;
        switch (payer.contract_status_label) {
          case 'Signed':
            var signedDate = moment(payer.last_contract_details.signed_datetime).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
            contract_status_label = 'Signed On: <i>' + signedDate + '</i>';
            break;
          case 'Declined':
            var declinedDate = moment(payer.last_contract_details.declined_datetime).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
            contract_status_label = ('Declined on: <i>' + declinedDate + '</i><br/>For reason: <i>' + payer.last_contract_details.decline_reason + '</i>');
            break;
          case 'Voided':
            var voidedDate = moment(payer.last_contract_details.voided_datetime).format(CONSTANTS.dateTimeFormatMoment.MEDIUM_ONLY_DATE);
            contract_status_label =  ('Voided on: <i>' + voidedDate + '</i>');
        }
        return contract_status_label;
      };

      portalHelperService.getCommaSeparatedNames = function (listOfUsers, type, row) {
        let listOfNames = _.pluck(listOfUsers, 'full_name').sort();
        let commaSeparatedNames = listOfNames.join(', ');
        return commaSeparatedNames;
      };

      portalHelperService.changeSurveyHoldStatus = function (newStatus, survey, processing_status) {
        var confirmationTitle = newStatus
          ? 'Put Survey on Hold?'
          : 'Resume Survey?';

        var confirmationMessage = newStatus
          ? 'Are you sure you want to put this survey on hold?'
          : 'Are you sure you want to resume this survey?';

        portalHelperService.showPopupYesNo(confirmationTitle,
          confirmationMessage,
          function () {
            var data = {
              'on_hold': newStatus
            };

            if (processing_status) {
              processing_status.is_processing_resume_survey_request = true;
            }
            var url = '/api/admin/survey/all/details/' + survey.id + '.json';
            HttpAuth.patch(apiHelper.getApiUrl() + url, data).then(function (result) {
              var currentHoldStatus = result.data.on_hold;
              survey.on_hold = currentHoldStatus;

              var message = currentHoldStatus
                ? 'The survey is now <strong>on Hold</strong>.'
                : 'The survey is no longer on hold.';
              portalHelperService.showToast(CONSTANTS.typeOfToasts.SUCCESS, message, 'Success');
            },
            portalHelperService.showErrorCommon
            ).finally(function () {
              if (processing_status) {
                processing_status.is_processing_resume_survey_request = false;
              }
            });
          });
      };


      portalHelperService.autosuggest_username = function(user) {
        if (user.id) {
          return;
        }

        if (user.first_name && user.last_name) {
          user.username = user.first_name.replace(/\s/g,'').toLowerCase() + '.'
             + user.last_name.replace(/\s/g,'').toLowerCase();
        }
      };

      portalHelperService.is_current_tab_active = (function () {
        var stateKey, eventKey, keys = {
          hidden: 'visibilitychange',
          webkitHidden: 'webkitvisibilitychange',
          mozHidden: 'mozvisibilitychange',
          msHidden: 'msvisibilitychange'
        };

        for (stateKey in keys) {
          if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
          }
        }

        return function (c) {
          if (c) document.addEventListener(eventKey, c);
          return !document[stateKey];
        };
      })();

      portalHelperService.ul_has_li_items = function (id) {
        let elem = angular.element('#' + id);
        if (!elem) {
          return null;
        }
        if (elem.length > 0) {
          return elem.find('li').length > 0;
        }
        return false;
      };

      portalHelperService.runCreditTransactionHistoryReport = function (options = {}) {
        if (options.event) {
          options.event.preventDefault();
        }

        let scope = $rootScope.$new(true);
        scope.selected = {'from_date': '', 'to_date': '', 'popup_error': '' };
        scope.open_calendar = function () {
          scope.calendar.opened = true;
        };

        scope.open_from_calendar = function () {
          scope.from_calendar.opened = true;
        };

        scope.open_to_calendar = function () {
          scope.to_calendar.opened = true;
        };

        scope.min_date_options = {
          startingDay: 1,
          showWeeks: false,
          maxDate: new Date()
        };

        scope.max_date_options = {
          startingDay: 1,
          showWeeks: false,
          maxDate: new Date()
        };

        scope.$watchGroup(['selected.from_date', 'selected.to_date'],
          function (newValues) {
            if (newValues[0]) {
              scope.max_date_options.minDate = new Date(newValues[0]);
            }
            if (newValues[1]) {
              scope.min_date_options.maxDate = new Date(newValues[1]);
            }
          }
        );

        scope.calendar = {
          opened: false
        };

        scope.from_calendar = {
          opened: false
        };

        scope.to_calendar = {
          opened: false
        };

        var popup = $ngConfirm({
          title: 'Report Date Range',
          columnClass: 'large',
          type: 'green',
          closeIcon: true,
          contentUrl: '../' + 'html_templates/dialogs/admin_credit_transaction_history_date_popup.e89420e3.html',
          scope: scope,
          escapeKey: 'Cancel',
          buttons: {
            OK: {
              btnClass: 'mat ui primary button',
              action: function () {
                if (!scope.selected.from_date) {
                  scope.selected.popup_error = 'Please enter from date.';
                  return false;
                }

                if (!scope.selected.to_date) {
                  scope.selected.popup_error = 'Please enter to date.';
                  return false;
                }

                let from_date_str = $filter('date')(
                  scope.selected.from_date,
                  CONSTANTS.dateTimeFormat.API_DATE);

                let to_date_str = $filter('date')(
                  scope.selected.to_date,
                  CONSTANTS.dateTimeFormat.API_DATE);

                var url = $window.location.pathname +
                '#/Admin/Reports/' +
                CONSTANTS.adminReports.CREDIT_TRANSACTION_HISTORY + '/' + from_date_str + '/' + to_date_str;
                if (options.type == 'client' && $routeParams.id && $routeParams.id != 0) {
                  url = url + '?client=' + $routeParams.id;
                }
                if (options.type == 'subscription' && $routeParams.licence_id && $routeParams.licence_id != 0) {
                  url = url + '?subscription=' + $routeParams.licence_id;
                }
                portalHelperService.popup_window_open(url);
              }
            },
            Cancel: {
              btnClass: 'mat ui critical button'
            }
          }
        });
      };

    }
  ]);
